<script setup>
import {BaseButton} from '@/shared/ui/Buttons'
import {BaseInput, BaseSelectBox} from '@/shared/ui/Inputs'

import {computed, ref} from 'vue'

import {disableEnableObjectList} from '@/shared/data'

import {ModalModel} from '@/entities/Modal'
import {useAppStore} from '@/app/providers'
import {PartnersModel} from '@/entities/Partners'
import {BaseModal} from '@/shared/ui/Modal'

const modalStore = ModalModel.useModalStore()
const appStore = useAppStore()
const partnersStore = PartnersModel.usePartnersStore()

const brand = computed(() => partnersStore.brand)

const brandName = ref(brand.value['brand.name']?.data || '')
const status = ref(brand.value['status'].data)
const close = () => {
    modalStore.closeModal()
}

const save = () => {
    appStore.update(
        {
            name: brandName.value,
            status: status.value
        },
        brand.value,
        'partners-brands-table'
    )
    close()
}

const cancel = () => {
    close()
}
</script>

<template>
    <BaseModal :title="'Edit brand'">
        <template #content>
            <div class="edit-affiliate">
                <div class="item">
                    <BaseInput
                        v-model="brandName"
                        :placeholder="'Enter brand name'"
                        :label="'Name'"
                    />
                </div>
                <div class="item">
                    <BaseSelectBox
                        v-model="status"
                        :label="'Access'"
                        :options="
                                Object.entries(disableEnableObjectList).map(([key, value]) => ({
                                    value: key,
                                    title: value
                                }))
                            "
                        :placeholder="disableEnableObjectList[status] || 'Select status'"
                    />
                </div>
            </div>
        </template>
        <template #action-buttons>
            <BaseButton :text="'Cancel'" :width="100" :design="'outline'" @click="cancel()"/>
            <BaseButton :text="'Save'" :width="100" @click="save()"/>
        </template>
    </BaseModal>
</template>

<style scoped>
.edit-affiliate {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.input-box,
.select {
    width: 100%;
}
</style>
