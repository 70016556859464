import { computed } from 'vue'
import { PartnersModel } from '@/entities/Partners'

export default {
    config: () => {
        const partnersStore = PartnersModel.usePartnersStore()
        const partner = computed(() => partnersStore.partner)

        return {
            companyInputs: computed(() => [
                {
                    key: 'companyName',
                    placeholder: 'Enter company name',
                    label: 'Company name',
                    value: partner.value ? partner.value['companyName'].data : ''
                },
                {
                    key: 'companyAddress',
                    placeholder: 'Enter company address',
                    label: 'Company address',
                    value: partner.value ? partner.value['companyAddress'].data : ''
                },
                {
                    key: 'companyRegNumber',
                    placeholder: 'Enter registration. number',
                    label: 'Registration number',
                    value: partner.value ? partner.value['companyRegNumber'].data : ''
                },
                {
                    key: 'companyVat',
                    placeholder: 'Enter VAT ID',
                    label: 'VAT ID',
                    value: partner.value ? partner.value['companyVat'].data : ''
                }
            ]),
            statusList: computed(() => ({
                0: 'Inactive',
                1: 'Active'
            }))
        }
    }
}
