<script setup>
import config from '@/pages/brand/configs/brand-affiliate-tab.config'
import {computed} from "vue"

const {cols, tableId, actionList, tableActions} = config.config()

import {DataTable} from '@/features/Datatable'
import {PaginationBox, FiltersList, TableActions} from '@/features/Datatable'

import {ModalModel} from '@/entities/Modal'
import {AffiliateModel} from '@/entities/Affiliates'
import {BrandsModel} from '@/entities/Brands'
import {BaseButton} from "@/shared/ui/Buttons"

const modalStore = ModalModel.useModalStore()
const affiliateStore = AffiliateModel.useAffiliatesStore()
const store = BrandsModel.useBrandsStore()

const brandID = computed(() => BrandsModel.getBrand(store).id.data)

const openEdit = (data) => {
    affiliateStore.setAffiliate(data)
    modalStore.setModal('editBrandAffiliate')
}

const openAdd = () => {
    modalStore.setModal('createAffiliate')
}
</script>

<template>
    <div class="single-tab">
        <DataTable
            v-if="brandID"
            :fields="cols"
            :path="`brand/${brandID}/affiliates/dt`"
            :table-id="tableId"
            :action-list="actionList"
            :option-list="tableActions"
            @open="openEdit"
        >
            <template #bottom-left>
                <PaginationBox/>
            </template>
            <template #top-left>
                <FiltersList :filters="[]" />
            </template>
            <template #center-right>
                <TableActions :actions="['export', 'reset', 'saveTemplate']"/>
            </template>
            <template #[`partner.username`]="{ row }">
                <span>{{ row.partner.data.firstname }} {{ row.partner.data.lastname }}</span>
            </template>
        </DataTable>
    </div>
</template>

<style scoped>
@media (max-width: 1000px) {
    .filters-list {
        display: block;
    }
}
</style>
