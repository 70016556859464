<script setup>
/**
 * TimePicker
 * ------------------------------
 * Description:
 * A Vue component for selecting time.
 *
 * Usage:
 * <TimePicker
 *    @update="handleTimeUpdate"
 * />
 *
 * Props:
 * - None
 *
 * Events:
 * - update: Emitted when the selected time changes. Provides the updated time value, return array of timestamps.
 */

import {ref, defineEmits, watch, defineProps, inject} from 'vue'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import {BaseImage} from '@/shared/ui/Images'

import {SessionModel} from '@/entities/Session'

const emits = defineEmits(['update', 'close'])
const props = defineProps({
    placeholder: {
        type: String,
        default: 'Date Range'
    },
    design: {
        type: String
    },
    isReactive: {
        type: Boolean,
        default: true
    }
})

const sessionStore = SessionModel.useSessionStore()

const filteredTime = ref({})
const timeRange = inject('timeRange') || {}
const timestamp = ref(timeRange)
const timePickerState = ref(false)
const initTime = ref(null)
const startTime = inject('startTime')
const localPlaceholder = ref('')
const hideRange = ref(false)

const shortcuts = ref([
    {
        text: 'Today',
        onClick: () => {
            timestamp.value.title = 'Today'
            timestamp.value = [
                Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0).getTime()),
                Math.floor(new Date().getTime())
            ]
            filteredTime.value.timestamp = [
                Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0).getTime()),
                Math.floor(new Date().getTime())
            ]
            hideRange.value = false
            updateCellCss()
            localPlaceholder.value = ""
        }
    },
    {
        text: 'Yesterday',
        onClick: () => {
            timestamp.value.title = 'Yesterday'
            timestamp.value = [
                Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1, 0, 0, 0).getTime()),
                Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1, 23, 59, 59).getTime()),
            ]
            filteredTime.value.timestamp = [
                Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1, 0, 0, 0).getTime()),
                Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1, 23, 59, 59,).getTime()),
            ]
            hideRange.value = false
            updateCellCss()
            localPlaceholder.value = ""
        }
    },
    {
        text: 'This Week',
        onClick: () => {
            const today = new Date()

            const startOfWeek = new Date(today)
            startOfWeek.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1))
            startOfWeek.setHours(0, 0, 0, 0)

            const endOfWeek = new Date(today)
            endOfWeek.setHours(23, 59, 59, 999)

            timestamp.value.title = 'This Week'
            timestamp.value = [
                Math.floor(startOfWeek.getTime()),
                Math.floor(endOfWeek.getTime())
            ]

            filteredTime.value.timestamp = [
                Math.floor(startOfWeek.getTime()),
                Math.floor(endOfWeek.getTime())
            ]

            hideRange.value = false
            updateCellCss()
            localPlaceholder.value = ""
        }
    },
    {
        text: 'This Month',
        onClick: () => {
            timestamp.value.title = 'This Month'
            timestamp.value = [
                Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime()),
                Math.floor(new Date().getTime())
            ]
            filteredTime.value.timestamp = [
                Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime()),
                Math.floor(new Date().getTime())
            ]
            hideRange.value = false
            updateCellCss()
            localPlaceholder.value = ""
        }
    },
    {
        text: 'Last Week',
        onClick: () => {
            const now = new Date()
            const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay() - 6))
            const endOfWeek = new Date(now.setDate(now.getDate() + (7 - now.getDay())))

            timestamp.value.title = 'Last Week'
            timestamp.value = [
                Math.floor(startOfWeek.setHours(0, 0, 0, 0)),
                Math.floor(endOfWeek.setHours(23, 59, 59, 999))
            ]

            filteredTime.value.timestamp = [
                Math.floor(startOfWeek.setHours(0, 0, 0, 0)),
                Math.floor(endOfWeek.setHours(23, 59, 59, 999))
            ]

            hideRange.value = false
            updateCellCss()
            localPlaceholder.value = ""
        }
    },
    {
        text: 'Last Month',
        onClick: () => {
            timestamp.value.title = 'Last Month'
            timestamp.value = [
                Math.floor(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).getTime()),
                Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime())
            ]
            filteredTime.value.timestamp = [
                Math.floor(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).getTime()),
                Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime())
            ]

            hideRange.value = false
            updateCellCss()
            localPlaceholder.value = ""
        }
    },
    {
        text: 'Last 30 Days',
        onClick: () => {
            const currentTime = new Date()
            const thirtyDaysAgo = new Date(currentTime.getTime() - (30 * 24 * 60 * 60 * 1000)) // 30 days in milliseconds

            timestamp.value.title = 'Last 30 Days'
            timestamp.value = [
                Math.floor(thirtyDaysAgo.getTime()),
                Math.floor(currentTime.getTime())
            ]
            filteredTime.value.timestamp = [
                Math.floor(thirtyDaysAgo.getTime()),
                Math.floor(currentTime.getTime())
            ]

            hideRange.value = false
            updateCellCss()
            localPlaceholder.value = ""
        }
    },
    {
        text: 'Last 60 Days',
        onClick: () => {
            const endDate = new Date()
            const startDate = new Date(endDate)
            startDate.setDate(endDate.getDate() - 59)
            timestamp.value.title = 'Last 60 Days'
            timestamp.value = [startDate.getTime(), endDate.getTime()]
            filteredTime.value.timestamp = [startDate.getTime(), endDate.getTime()]
            hideRange.value = false
            updateCellCss()
            localPlaceholder.value = ""
        }
    },
    {
        text: 'All Time',
        onClick: () => {
            const startDate = new Date(1);
            const endDate = props.isReactive ? new Date() : null;

            if (props.isReactive) {
                timestamp.value = {}
                initTime.value = {}
                filteredTime.value.timestamp = [startDate.getTime() * 1000, endDate.getTime() * 1000];
                localPlaceholder.value = 'All Time'
                open()
                emits('update', filteredTime.value)
            } else {
                timestamp.value = startDate.getTime();
                filteredTime.value.timestamp = startDate.getTime();
            }
        }
    }
])

const setTime = () => {
    const timestamps = [...timestamp.value]
    const lastIndex = timestamps.length - 1

    timestamps[lastIndex] = new Date(timestamps[lastIndex])
        .setDate(new Date(timestamps[lastIndex]).getDate() + 1)

    timestamps[lastIndex] = new Date(timestamps[lastIndex]).setHours(0, 0, 0, 0)

    initTime.value = timestamp.value
    if (!localPlaceholder.value) {
        filteredTime.value.timestamp = timestamps
    }

    emits('update', filteredTime.value)
}

const clear = () => {
    timestamp.value = {}
    filteredTime.value = {}
    emits('update', timestamp.value)
}

const updateCellCss = () => {
    setTimeout(() => {
        const activeCells = document.querySelectorAll('.cell.active')
        const rangeCells = document.querySelectorAll('.cell.in-range')

        if (rangeCells.length) {
            rangeCells.forEach((cell) => {
                cell.style.setProperty('border-radius', '0 0 0 0', 'important')
            })
        }

        if (activeCells.length) {
            activeCells.forEach((cell, index) => {
                if (index === 0) {
                    cell.style.setProperty('border-radius', '5px 0 0 5px', 'important')
                } else {
                    cell.style.setProperty('border-radius', '0 5px 5px 0', 'important')
                }
            })
        }
    }, 1)
}
const open = () => {
    timePickerState.value = !timePickerState.value

    if (!props.isReactive) {
        timestamp.value = initTime.value
    }

    const timePickerContent = document.querySelector('.mx-datepicker-main')
    const timeArrow = document.querySelector('.mx-icon-calendar')

    const activeCells = document.querySelectorAll('.cell.active')
    const activeRange = document.querySelectorAll('.cell.in-range')

    if (timePickerContent) {
        timeArrow.classList.add('active')
        if (!Object(timestamp.value).length) {
            activeCells.forEach((cell) => cell.classList.remove('active'))
            activeRange.forEach((cell) => cell.classList.remove('in-range'))
        }

        if (!timePickerState.value) {
            timePickerContent.classList.add('hide')
            timeArrow.classList.remove('active')
        } else {
            timePickerContent.classList.remove('hide')
        }
    }
}

const close = () => {
    document.querySelector('.mx-icon-calendar').classList.remove('active')
    timePickerState.value = false
    setTimeout(() => {
        timestamp.value = initTime.value
        emits('close')
    }, 1)
}

const preOpen = () => {
    initTime.value = timestamp.value
}

watch(
    () => sessionStore.clearFilters,
    () => {
        if (props.isReactive) {
            timestamp.value = {}
        }
        localPlaceholder.value = ''
    },
    {
        deep: true
    }
)
</script>

<template>
    <DatePicker
        v-model:value="timestamp"
        :class="[design]"
        :shortcuts="shortcuts"
        :confirm="true"
        :editable="false"
        :format="'DD-MM-YY'"
        :confirm-text="'Confirm'"
        :value-type="'timestamp'"
        :placeholder="localPlaceholder || placeholder"
        range
        @open="preOpen"
        @click.stop="open()"
        @confirm="setTime"
        @clear="clear"
        @close="close()"
        @pick="updateCellCss"
    >
        <template #icon-calendar>
            <BaseImage :width="10" :height="10" :path="'arrow.svg'" class="arrow"/>
        </template>
    </DatePicker>
</template>

<style scoped></style>
