<script setup>
import { computed, ref } from 'vue'

import { BaseWarningBox } from '@/shared/ui/Warnings'
import { BaseButton } from '@/shared/ui/Buttons'
import { BaseCheckBox, BaseInput } from '@/shared/ui/Inputs'

import { DatatableModel } from '@/entities/Datatable'
import { ModalModel } from '@/entities/Modal'
import { TemplatesModel } from '@/entities/Templates'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import { BaseModal } from '@/shared/ui/Modal'

const store = TemplatesModel.useTemplatesStore()
const datatableStore = DatatableModel.useDatatableStore()
const modalStore = ModalModel.useModalStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

const tableId = computed(() => DatatableModel.getTableId(datatableStore))
const savedTemplates = computed(() => TemplatesModel.getSavedTemplates(store, tableId.value))

const filterName = ref('')
const warningMessage = ref('')
const saveByDefault = ref(false)
const saving = ref(false)

const close = () => {
    modalStore.closeModal()
}

const save = () => {
    if (!filterName.value) {
        generalNotificationsStore.showError({
            message: 'Please enter a name'
        })
        return
    }

    let filterExists = false
    if (Object.keys(savedTemplates.value).length) {
        filterExists = savedTemplates.value.some((filter) => filter.name === filterName.value)
    }

    saving.value = true

    if (filterExists) {
        generalNotificationsStore.showError({
            message: 'Template already exist'
        })
        saving.value = false
        return
    }

    store
        .saveTemplate({
            name: filterName.value
        })
        .catch(() => {})
        .finally(() => {
            saving.value = false
        })

    filterName.value = ''
    warningMessage.value = ''
    close()
}

const changeState = () => {
    saveByDefault.value = !saveByDefault.value
}
</script>

<template>
    <BaseModal >
        <template #title>
            <h4 class="typo-heading-h4">Save columns</h4>
        </template>
        <template #content>
            <div class="save-filter-modal" @keypress.enter="save()">
                <BaseInput
                    v-model="filterName"
                    :placeholder="'Enter a name for column'"
                    @keypress="warningMessage = ''"
                />
                <BaseWarningBox v-if="warningMessage" :text="warningMessage" :type="'error'" />
                <div class="save-by-default" @click="changeState">
                    <BaseCheckBox :value="saveByDefault" :checked="saveByDefault" @checked="changeState" />
                    <p>Set as default to all users</p>
                </div>
            </div>
        </template>
        <template #action-buttons>
            <BaseButton :design="'outline'" :text="'Cancel'" :height="40" @click="close()" />
            <BaseButton :text="'Save'" :load="saving" :height="40" @click.stop="save()" />
        </template>
    </BaseModal>
</template>

<style scoped>
.typo-heading-h4 {
    padding: 9px 0;
}

.save-filter-modal {
    display: grid;
    place-items: center;
    grid-template-rows: max-content max-content 1fr max-content;
}

.warning-box {
    margin-bottom: -30px;
    text-align: center;
}

.input-box {
    width: 100%;
}

.save-by-default {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 15px 0 0 11px;
    cursor: pointer;
    width: 100%;
}

.save-by-default p {
    font-size: 12px;
}

.button-box.fill,
.button-box.outline {
    width: 100px !important;
}
</style>
