<script setup>
import {computed, ref, onMounted} from 'vue'
import {useRouter} from 'vue-router'

import {HeaderModel} from '@/entities/Header'
import {AuthorizationModel} from '@/features/Authorization'
import {UserModel} from '@/entities/User'
import {ModalModel} from '@/entities/Modal'
import {SessionModel} from '@/entities/Session'

import {BaseImage} from '@/shared/ui/Images'
import {OptionList} from '@/shared/ui/Panels'

const headerStore = HeaderModel.useHeaderStore()
const authorizationStore = AuthorizationModel.useAuthorizationStore()
const modalStore = ModalModel.useModalStore()
const userStore = UserModel.useUserStore()
const sessionStore = SessionModel.useSessionStore()
const router = useRouter()

onMounted(() => {
    setInterval(() => {
        updateTime()
    }, 1000)
})

const defaultRoute = computed(() => HeaderModel.getDefaultRoute(headerStore))
const baseHeaderLinks = computed(() => HeaderModel.getBaseHeaderLinks(headerStore))
const timeZone = computed(() => sessionStore.offset)
const user = computed(() => userStore.user)
const homeUrl = computed(() => headerStore.homeUrlName)

const depositCount = computed(() => {
    const depositFound = sessionStore.timeDepositsFound
    const depositFoundLS = sessionStore.timeDepositsFoundLS

    let unequalCount = 0

    for (let i = 0; i < depositFound.length; i++) {
        const item = depositFound[i]
        const match = depositFoundLS.some(lsItem => lsItem.id === item.id)
        if (!match) {
            unequalCount++
        }
    }

    return unequalCount
})

const personalMenuVisible = ref(false)
const timeMenuVisible = ref(false)

const formattedTime = ref('')
const formattedDate = ref('')
const currentUTC = ref('')
const burgerIsOpen = ref(false)

const userOptionList = [
    {
        key: 'profile',
        value: 'My Profile',
        func: () => {
            router.push({name: 'profilePage'})
        }
    },
    {
        key: 'changeProfile',
        value: user.value.id === localStorage.getItem('id') ? 'Change Profile' : 'Switch Back',
        func: () => {
            if (user.value.id === localStorage.getItem('id')) {
                modalStore.setModal('changeProfile')
            } else {
                authorizationStore.logout(false)
            }
        }
    },
    {
        key: 'statuses',
        value: 'Statuses',
        right: 'statuses',
        func: () => {
            router.push({name: 'statusesPage'})
        }
    },
    {
        key: 'integrationTest',
        value: 'Integration Test',
        right: 'admin',
        func: () => {
            router.push({name: 'integrationTest'})
        }
    },
    {
        key: 'logout',
        value: 'Logout',
        func: () => {
            authorizationStore.logout()
        }
    },
]

const timeMenuList = [
    {
        key: 'utc',
        value: 'UTC Time',
        func: () => {
            setTime(1)
        }
    },
    {
        key: 'local',
        value: 'Local Time',
        func: () => {
            setTime(2)
        }
    },
]

const closeBurgerMenu = () => {
    if (burgerIsOpen.value) {
        burgerIsOpen.value = false
        document.removeEventListener('click', closeBurgerMenu)
    }
}

const applyOffset = (date, offset) => {
    const utc = date.getTime() + (date.getTimezoneOffset() * 60000)
    return new Date(utc + (offset * 3600000))
}

const updateTime = () => {
    const now = new Date()
    const time = timeZone.value !== null ? timeZone.value : now.getTimezoneOffset()

    const adjustedDate = applyOffset(now, time / 60 * -1)

    formattedTime.value = adjustedDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    })

    formattedDate.value = adjustedDate.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
    })

    const offsetHours = Math.abs(Math.floor(time / 60))
    let offsetSign = ''

    if (time > 0) {
        offsetSign = '-'
    } else if (time < 0) {
        offsetSign = '+'
    }

    currentUTC.value = `UTC ${offsetSign}${offsetHours ? offsetHours.toString() : ''}`
}

const setTime = (type) => {
    const now = new Date()
    let zone = 0

    if (type === 1) {
        sessionStore.setOffset(zone)
    } else {
        zone = now.getTimezoneOffset()
        sessionStore.setOffset(zone)
    }
    localStorage.setItem('timeZone', zone)
    updateTime()
}

const showUserMenu = () => {
    timeMenuVisible.value = false
    burgerIsOpen.value = false
    document.removeEventListener('click', closeBurgerMenu)
    personalMenuVisible.value = !personalMenuVisible.value
}

const showTimeMenu = () => {
    personalMenuVisible.value = false
    timeMenuVisible.value = !timeMenuVisible.value
}

const showMobileTimeMenu = () => {
    timeMenuVisible.value = !timeMenuVisible.value
}

const toggleBurgerMenu = () => {
    burgerIsOpen.value = !burgerIsOpen.value
    timeMenuVisible.value = false
    setTimeout(() => {
        if (burgerIsOpen.value) {
            document.addEventListener('click', closeBurgerMenu)
        } else {
            document.removeEventListener('click', closeBurgerMenu)
        }
    }, 1)
}

const sendAction = (item) => {
    item.func ? item.func() : ''
    personalMenuVisible.value = false
}

router.beforeEach((from, to, next) => {
    burgerIsOpen.value = false
    document.removeEventListener('click', closeBurgerMenu)
    next()
})
</script>

<template>
    <header class="header">
        <router-link :to="{ name: homeUrl }" class="logo">
            <BaseImage :path="'logo/logo-white.png'" :width="120" :height="30"/>
        </router-link>
        <div class="routes-container">
                       <div class="burger-menu" :class="{active: burgerIsOpen}" @click="toggleBurgerMenu">
                <BaseImage :width="20" :height="11" :path="'burger-menu.svg'"/>
            </div>
            <div class="routes-list" :class="{show: burgerIsOpen}">
                <router-link :to="{ name: defaultRoute.name }" class="dashboard">
                    <BaseImage :path="`${defaultRoute.icon}`" :width="30" :height="30"/>
                    <h6>{{ defaultRoute.title }}</h6>
                </router-link>
                <div class="routers">
                    <router-link
                        v-for="route in baseHeaderLinks" :key="route.name" :to="{ name: route.name }"
                        class="router">
                        <BaseImage :path="`${route.icon}`" :width="30" :height="30"/>
                        <h6>{{ route.title }}</h6>
                        <div v-if="route.name === 'depositsPage' && depositCount" class="circle-info">
                            {{depositCount}}
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="notification">
                <BaseImage :height="36" :width="32" :path="'notification.svg'"/>
            </div>
            <div class="user-options" :class="{active: personalMenuVisible}">
                <BaseImage
                    :height="36"
                    :width="36"
                    :path="'user.svg'"
                    class="user-info"
                    @click.stop="showUserMenu()"
                />
                <div class="user-menu-container">
                    <div class="time-box">
                        <div class="time-container">
                            <div class="time">{{ formattedTime }}</div>
                            <div class="date">{{ formattedDate }}</div>
                        </div>
                        <div class="zone" @click.stop="showTimeMenu()">
                            {{ currentUTC }}
                            <BaseImage
                                v-if="currentUTC" class="arrow" :class="{active: timeMenuVisible}"
                                :path="'arrow.svg'" :height="15" :width="15"/>
                        </div>
                        <OptionList v-model="timeMenuVisible" class="time-menu" :list="timeMenuList"/>
                    </div>
                    <OptionList v-model="personalMenuVisible" class="user-menu" :list="userOptionList"/>
                </div>
                <div class="mobile-user-menu-container" :class="{show: personalMenuVisible}" @click.stop>
                    <span class="user-name">{{ user.firstname }} {{ user.lastname }}</span>
                    <div class="time-box">
                        <div class="time-container">
                            <div class="time">{{ formattedTime }}</div>
                            <div class="date">{{ formattedDate }}</div>
                        </div>
                        <div class="zone" @click.stop="showMobileTimeMenu()">
                            {{ currentUTC }}
                            <BaseImage
                                v-if="currentUTC" class="arrow" :class="{active: timeMenuVisible}"
                                :path="'arrow.svg'" :height="6" :width="11"/>
                            <OptionList v-model="timeMenuVisible" class="time-menu" :list="timeMenuList"/>
                        </div>
                    </div>
                    <ul>
                        <li v-for="item in userOptionList" :key="item.key" @click="sendAction(item)">
                            {{ item.value }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<style scoped>
.header {
    position: relative;
    padding: 8px var(--gutter);
    background-color: #7075e7;
    display: inline-grid;
    place-items: center stretch;
    grid-template-columns: max-content 1fr max-content;
    width: 100%;
}

.burger-menu {
    display: none;
}

.logo {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 20px;
    border-right: var(--general-border) solid var(--primary-white);
}

.routes-list,
.routes-container {
    display: flex;
    align-items: center;
}

.dashboard {
    padding: 0 40px;
}

.dashboard,
.router {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 5px;
    flex-direction: column;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
    border-radius: var(--general-border-radius);
    position: relative;
}

.routers {
    display: flex;
    align-items: center;
    padding-left: 40px;
    height: 100%;
    border-left: var(--general-border) solid var(--primary-white);
}

.router {
    position: relative;
    padding-right: 40px;
}

.dashboard.router-link-active::after,
.router.router-link-active::after {
    content: '';
    position: absolute;
    width: 110px;
    top: 50%;
    left: calc(50% - 20px);
    transform: translate(-50%, -50%);
    height: 65px;
    border-radius: 4px;
    background-color: var(--white-024);
}

.dashboard.router-link-active::after {
    left: 50%;
}

.info {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 24px;
    padding: 0 20px;
    border-right: var(--general-border) solid var(--primary-white);
    border-left: var(--general-border) solid var(--primary-white);
}

.user-options {
    position: relative;
}

.notification {
    position: relative;
    cursor: pointer;
}

.notification .image:first-child {
    position: relative;
    top: 2px;
}

.notification-counter {
    position: absolute;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff5e5e;
    top: -5px;
    right: -2px;
}

.user-info {
    cursor: pointer;
}

.user-menu-container,
.user-options {
    display: flex;
    align-items: center;
}

.month {
    text-transform: uppercase;
    font-size: 13px;
    position: relative;
    top: -7px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.arrow {
    transition: all .3s ease-in-out;
    transform: rotate(0);
}

.arrow.active {
    transform: rotate(180deg);
}

.time-container {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.time-box {
    padding-left: 16px;
    display: flex;
    gap: 8px;
}

.zone {
    border-bottom: var(--general-border) solid var(--white-024);
    font-size: 16px;
    width: 100%;
    cursor: pointer;
}

.zone img {
    margin-left: 12px;
}

.time-box .top {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.option-list.bottom.user-menu {
    top: 60px;
    left: 0;
}

.option-list.bottom.time-menu {
    right: 0;
    top: 60px;
    left: auto;
}

.mobile-user-menu-container {
    display: none;
}

.circle-info {
    position: absolute;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white-086);
    color: var(--dark-purple);
    font-size: 13px;
    font-weight: 600;
    top: -3px;
    right: 50px;
}

@media (max-width: 1250px) {
    .header {
        grid-template-columns: max-content 1fr max-content;
    }

    .time-box {
        padding: 0;
    }

    .burger-menu {
        display: flex;
        background-color: transparent;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color .3s ease-in-out;
    }

    .burger-menu.active {
        background-color: var(--white-024);
    }

    .info {
        border: none;
        justify-content: flex-end;
        gap: 24px;
        padding: 0;
    }

    .logo {
        height: 30px;
        border: none;
        padding: 0;
        grid-area: 1/2;
        justify-self: center;
    }

    .routes-list {
        opacity: 0;
        display: none;
        visibility: hidden;
        background-color: transparent;
    }

    .routes-list.show {
        opacity: 1;
        display: block;
        visibility: visible;
        background-color: var(--purple);
        position: absolute;
        top: 55px;
        left: 0;
        z-index: 10;
        flex-direction: column;
        width: 205px;
        border-radius: 0 0 8px 0;
        padding: 16px 0 24px;
    }

    .routers {
        flex-direction: column;
        padding: 0;
        border: none;
        align-items: baseline;
    }

    .dashboard {
        padding: 10px 25px;
        margin-bottom: 8px;
        position: relative;
    }

    .dashboard::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        height: var(--general-border);
        width: 158px;
        background-color: var(--white-024);
        transform: translateX(-50%);
    }

    .dashboard,
    .router {
        flex-direction: row;
        width: 100%;
        border-radius: 0;
        gap: 14px;
        font-size: 14px;
        font-weight: 400;
    }

    .dashboard img,
    .router img {
        width: 20px;
        height: 20px;
    }

    .router {
        padding: 10px 25px;
        border-radius: 0;
    }

    .dashboard.router-link-active::after,
    .router.router-link-active::after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.3);
        transform: none;
        border-radius: 0;
    }

    .notification img,
    .user-info {
        width: 19px;
        height: 19px;
    }

    .user-name {
        margin-bottom: 13px;
        text-align: right;
        width: 100%;
        display: inline-block;
    }

    .user-options {
        padding: 10.5px;
    }

    .user-options.active {
        background-color: var(--white-024);
        border-radius: 4px;
    }

    .user-menu-container {
        display: none;
    }

    .mobile-user-menu-container {
        display: block;
        position: absolute;
        top: 46px;
        right: -20px;
        z-index: 10;
        background: var(--purple);
        padding: 8px 24px 24px;
        width: 215px;
        border-radius: 0 0 0 8px;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s ease-in-out;
    }

    .mobile-user-menu-container.show {
        opacity: 1;
        visibility: visible;
    }

    .mobile-user-menu-container ul li {
        padding: 14px 0;
        border-bottom: 1px solid var(--white-024);
        font-size: var(--general-font-size);
        text-align: right;
        cursor: pointer;
    }

    .option-list.bottom.time-menu {
        top: 85px;
        border-radius: 0 0 var(--general-border-radius) var(--general-border-radius);
        width: 43%;
        right: 22px;
    }
}

@media (max-width: 768px) {
    .zone,
    .time-container {
        font-size: 12px;
    }

    .zone img {
        margin-left: 22px;
    }

    .option-list.bottom.time-menu {
        top: 75px;
        width: 50%;
        right: 23px;
    }
}
</style>
