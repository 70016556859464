<script setup>
import {computed, onMounted, shallowRef} from 'vue'
import { router } from '@/app/providers'

import config from '@/pages/brand/configs/brand.config'

const {tabs} = config.config()

import {BaseTabBox} from '@/shared/ui/Tabs'
import {BaseBack} from '@/shared/ui/Actions'
import {BaseTabViewer, BaseTabViewerItem} from '@/shared/ui/Tabs'
import {SinglePageTitle} from '@/shared/ui/Page'

import {ModalModel} from '@/entities/Modal'
import {BrandsModel} from '@/entities/Brands'
import {usePageTitle} from '@/shared/lib/use/usePageTitle'
import {BaseModal} from "@/shared/ui/Modal";
import {BaseButton} from "@/shared/ui/Buttons";

onMounted(() => {
    if (!brand.value) {
        store.getBrand(brandID).then(() => {
            title = usePageTitle(brand.value['name'].data)
        })
    }
})

const brandID = router.currentRoute.value.params.id

const modalStore = ModalModel.useModalStore()
const store = BrandsModel.useBrandsStore()

const brand = computed(() => BrandsModel.getBrand(store))

const availableActionPartners = ['affiliates', 'paymentPlan', 'restrictions']
const actionAvailable = computed(() => availableActionPartners.includes(activeTab.value.key))
let title

if (brand.value) {
    title = usePageTitle(brand.value['name'].data)
}

const activeTab = shallowRef(tabs[0])
const setActiveTab = (tab) => {
    activeTab.value = tab
}

const makeAction = () => {
    switch (activeTab.value.key) {
        case 'affiliates':
            modalStore.setModal('createAffiliate')
            break
        case 'paymentPlan':
            modalStore.setModal('createPlan')
            break
        case 'restrictions':
            break
        default:
            break
    }
}
</script>

<template>
    <div class="single-page container">
        <SinglePageTitle
            :icon="'page-icons/brands.svg'"
            :title="brand ? brand.name.data : ''"
            :action-available="actionAvailable"
            :loaded="!!brand"
            @action="makeAction"
        />
        <BaseBack :path-name="'brandsPage'" />
        <BaseTabBox :tabs="tabs" :active="activeTab" @change-tab="setActiveTab" />
        <BaseTabViewer>
            <template #content>
                <BaseTabViewerItem :load="!!brand" :component="activeTab.component" />
            </template>
        </BaseTabViewer>
    </div>
</template>

<style scoped>
.tabs {
    margin-top: 24px;
}

@media (max-width: 1000px) {
    button {
        display: none;
    }
}
</style>
