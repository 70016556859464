<script setup>
import {ModalModel} from '@/entities/Modal'
import {SessionModel} from '@/entities/Session'

import {BaseModal} from "@/shared/ui/Modal"
import {computed} from "vue"

import {timeFormat} from '@/shared/lib/utils/Date/format'

const sessionStore = SessionModel.useSessionStore()
const modalStore = ModalModel.useModalStore()

const callStatusInfo = computed(() => modalStore.modalData)
</script>

<template>
    <BaseModal >
        <template #title>
            <h4 class="typo-heading-h4">Status history</h4>
        </template>
        <template #content>
            <ul v-if="callStatusInfo && callStatusInfo.length">
                <li v-for="(item, i) in callStatusInfo" :key="i">
                    {{ timeFormat(item.updatedAt, sessionStore.offset) }} - {{item.callStatus}}
                </li>
            </ul>
            <span v-else>No statuses history</span>
        </template>
    </BaseModal>
</template>

<style scoped>
ul {
    margin-top: 4px;
    max-height: 216px;
    overflow: auto;
}

span {
    font-size: 16px;
    text-align: center;
    border-top: 1px solid var(--white-08);
    padding-top: 16px;
}

ul li {
    margin-bottom: 8px;
    color: var(--white-086);
    font-size: 16px;
}
</style>
