<script setup>
import { computed } from 'vue'

import { DatatableModel } from '@/entities/Datatable'
import { BaseSelectBox } from '@/shared/ui/Inputs'

const datatableStore = DatatableModel.useDatatableStore()

const setLimit = (value) => {
    datatableStore.resetLimit(value)
    datatableStore.setDataTableConfig([
        {
            field: 'limit',
            type: 'limit',
            data: value || limit.value
        }
    ])
}

const tableId = computed(() => DatatableModel.getTableId(datatableStore))
const limit = computed(() => datatableStore.datatableConfig.limit)
const loadedData = computed(() => datatableStore.datatableData[tableId.value])
const pageLength = computed(() => datatableStore.pageLength)
</script>

<template>
    <div class="pagination">
        <span class="typo-heading-small-semi-bold-gray"> View </span>
        <BaseSelectBox
            v-if="limit"
            v-model="limit"
            :design="'pagination'"
            :placement="'bottom'"
            :width="70"
            :options="
                pageLength.map((i) => ({
                    value: i,
                    title: i.toString()
                }))
            "
            :placeholder="limit.toString()"
            @update="(option) => setLimit(option.value)"
        >
            <template #option="option">
                {{ option.option.value }}
            </template>
        </BaseSelectBox>
        <span v-if="limit" class="typo-heading-small-semi-bold-gray">
            items per page | Showing {{ loadedData.length || 0 }} results
        </span>
    </div>
</template>

<style scoped>
.pagination {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

@media(max-width: 768px) {
    span {
        max-width: 140px;
    }
}
</style>
