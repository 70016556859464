<script setup>
import {defineProps, reactive, toRef, ref, onMounted, defineEmits, watch, provide, computed} from 'vue'

import {debounce} from '@/shared/lib/utils/debounce'

import {BaseMultiselect, BaseSelectBox, BaseToggle} from '@/shared/ui/Inputs'
import {BaseButton} from '@/shared/ui/Buttons'
import {TimePicker} from '@/features/Time'
import {BaseClear} from '@/shared/ui/Actions'

import {DatatableModel} from '@/entities/Datatable'
import {SessionModel} from '@/entities/Session'
import {BaseImage} from "@/shared/ui/Images"
import {SearchInput} from "@/features/Search"

const datatableStore = DatatableModel.useDatatableStore()
const sessionStore = SessionModel.useSessionStore()

const emits = defineEmits(['search', 'clear'])

const props = defineProps({
    filters: {
        type: Array,
        default: () => []
    },
    buttonText: {
        type: String,
        default: 'Search'
    },
    leaveFilter: {
        type: Object,
        default: () => {
        }
    },
    id: {
        type: String
    },
    levels: {
        type: Object,
        default: () => {
        }
    },
    levelsFields: {
        type: Array,
        default: () => []
    },
    // todo remove in future
    searchList: {
        type: Array
    },
})

onMounted(() => {
    let selects = props.filters.filter((el) => el.type === 'select')
    for (const item in selects) {
        selectData.value[selects[item].field] = ''
    }
})

const levelsData = ref([])
const filtersData = reactive({})
const localFilters = toRef(props, 'filters')
const selectData = ref({})
const filterIsOpen = ref(false)
const searchIsOpen = ref(true)
const isDatatableUpdated = ref(false)
const searchValue = ref('')
const currentOpenLevel = ref(null)

const multiselectHandle = (value) => {
    const key = Object.keys(value)[0] || ''
    if (key === 'type') {
        sessionStore.setResetSelectingFilterList()
    }

    const firstValue = value[key][0] || ''

    filtersData[key] = firstValue?.key !== undefined ? (isNaN(firstValue.key) ? firstValue.key : parseInt(firstValue.key)) : value[key]

    if (!Object.keys(filtersData[key]).length) {
        delete filtersData[key]
    }
}

const search = () => {
    let data = [],
        config = {},
        configArray = []
    for (const item in filtersData) {
        let obj = {}
        obj.field = item
        obj.data = filtersData[item]
        data.push(obj)
    }
    filterIsOpen.value = false
    switch (props.id) {
        case 'dashboard':
            for (const i in data) {
                config[data[i].field] = data[i].data
            }
            emits('search', config)
            break
        case 'reports':
            for (const item of data) {
                if (item.field === 'timeCreate') {
                    configArray.push(item)
                }
            }

            if (levelsData.value.length) {
                configArray.push({
                    field: 'levels',
                    data: levelsData.value
                })
            }

            datatableStore.setDataTableConfig(configArray)
            break
        default:
            datatableStore.setDataTableConfig(data)
            break
    }

    isDatatableUpdated.value = true
}

const adjustForOffset = (timestamp, offset) => timestamp - (offset * 60)

const setTime = (time, value) => {
    let gteValue,
        lteValue,
        matches,
        timeGteLte,
        queryParam = {}

    if (Object.keys(time).length) {
        switch (time.title) {
            case 'Yesterday':
            case 'Past Week':
            case 'Past Month':
                timeGteLte = `$gt=${time.timestamp[0]}&$lte=${time.timestamp[1]}`
                matches = timeGteLte.match(/\$gt=(\d+)&?\$lte=(\d+)?/)
                if (matches && matches.length >= 3) {
                    gteValue = Number(matches[1])
                    lteValue = Number(matches[2])
                }
                break
            default:
                gteValue = Math.floor(time.timestamp[0])
                lteValue = Math.floor(time.timestamp[1])
                break
        }

        if (gteValue !== undefined && gteValue > 0) {
            queryParam.$gt = gteValue / 1000
            // todo make simple
            if (parseInt(sessionStore.offset) !== 0) {
                queryParam.$gt = adjustForOffset(queryParam.$gt, 0)
            } else {
                queryParam.$gt = adjustForOffset(queryParam.$gt, new Date().getTimezoneOffset())
            }
        }

        if (lteValue !== undefined && lteValue > 0) {
            queryParam.$lte = lteValue / 1000
            // todo make simple
            if (parseInt(sessionStore.offset) !== 0) {
                queryParam.$lte = adjustForOffset(queryParam.$lte, 0)
            } else {
                queryParam.$lte = adjustForOffset(queryParam.$lte, new Date().getTimezoneOffset())
            }
        }
    }

    filtersData[value.field] = queryParam
    if (!Object.keys(filtersData[value.field]).length) {
        delete filtersData[value.field]
    }

    if (!Object.keys(time).length) {
        search()
    }
}

const clearFilters = () => {
    sessionStore.clearFilter()
    switch (props.id) {
        case 'dashboard':
            emits('clear', {})
            return
        default:
            if (isDatatableUpdated.value) {
                datatableStore.clearFilters(props.leaveFilter)
            }

            levelsData.value = []
            selectData.value = {}
            Object.keys(filtersData).forEach(key => {
                delete filtersData[key]
            })
            return
    }
}

const debouncedClearFilters = debounce(clearFilters)

const selectHandle = (data) => {
    if (data.value || data.value.length) {
        selectData.value[data.key] = data
        filtersData[data.key] = isNaN(data.value) ? data.value : parseInt(data.value)
    } else {
        delete selectData.value[data.key]
        delete filtersData[data.key]
    }
}

const closeFiltersMenu = () => {
    if (filterIsOpen.value) {
        filterIsOpen.value = false
        document.removeEventListener('click', closeFiltersMenu)
    }
}

const toggleFilters = () => {
    filterIsOpen.value = !filterIsOpen.value
    setTimeout(() => {
        if (filterIsOpen.value) {
            document.addEventListener('click', closeFiltersMenu)
        } else {
            document.removeEventListener('click', closeFiltersMenu)
        }
    }, 1)
    searchIsOpen.value = false
    searchValue.value = ''
}

const computedLevelsFields = computed(() =>
    levelsData.value.length === 0
        ? [props.levelsFields[0]]
        : props.levelsFields.slice(0, levelsData.value.length + 1)
)

const levelOptions = computed(() => {
    let levelOptions = { ...props.levels }
    const currentLevelIndex = currentOpenLevel.value

    for (const item in selectData.value) {
        const selectedLevel = Number(item)
        if (selectedLevel < currentLevelIndex) {
            delete levelOptions[selectData.value[item].key]
        }
    }

    return Object.entries(levelOptions)
        .map(([key, value]) => ({
            value: key,
            title: value,
            key: key
        }))
})

const setLevels = (level, index) => {
    if (level.value) {
        selectData.value[index] = level
        levelsData.value[index] = level.value
        filtersData[level.key] = level.value

        for (let i = index + 1; i < Object.keys(selectData.value).length; i++) {
            if (selectData.value[i] && selectData.value[i].key === level.key) {
                delete selectData.value[i]
                delete levelsData.value[i]
            }
        }

        levelsData.value = levelsData.value.slice(0, index + 1)

        Object.keys(filtersData).forEach((key) => {
            if (!levelsData.value.includes(filtersData[key]) && key !== 'timeCreate') {
                delete filtersData[key]
            }
        })
    }
}

const startSearch = (data) => {
    if (!data.value) {
        searchIsOpen.value = false
        return
    }

    switch (data.key) {
        case 'email':
            data.value = data.value.trim()
            break
        default:
            break
    }

    datatableStore.setDataTableConfig([
        {
            field: data.key,
            data: data.value,
            type: 'globalFilter'
        }
    ], props.leaveFilter)
}

const openLevel = (index) => {
    currentOpenLevel.value = index
}

watch(
    () => sessionStore.resetSelect,
    (value) => {
        if (value) {
            delete filtersData[value]
        }
    }
)

watch(
    () => sessionStore.offset,
    (newOffset) => {
        if (props.id === 'dashboard') {
            return
        }

        let timeCreatedExist = filtersData && Object.keys(filtersData.timeCreate).length

        if (timeCreatedExist && parseInt(newOffset) !== 0) {
            filtersData.timeCreate.$gt = adjustForOffset(filtersData.timeCreate.$gt, newOffset * -1)
            filtersData.timeCreate.$lte = adjustForOffset(filtersData.timeCreate.$lte, newOffset * -1)
        } else if (timeCreatedExist) {
            filtersData.timeCreate.$gt = adjustForOffset(filtersData.timeCreate.$gt, new Date().getTimezoneOffset())
            filtersData.timeCreate.$lte = adjustForOffset(filtersData.timeCreate.$lte, new Date().getTimezoneOffset())
        }

        if (timeCreatedExist) {
            search()
        }
    }
)
</script>

<template>
    <div class="filters-list">
        <div class="header" :class="id">
            <slot v-if="$slots['custom-button']" name="custom-button"/>
            <div class="filter-icon" :class="{active: filterIsOpen, exist: Object.keys(localFilters).length}" @click="toggleFilters()">
                <BaseImage :height="16" :width="16" :path="'filters.svg'"/>
            </div>
            <template v-if="id !== 'dashboard'">
                <div
                    v-if="!searchIsOpen" class="search-icon" :class="{active: filterIsOpen}"
                    @click.stop="searchIsOpen = true">
                    <BaseImage :height="20" :width="20" :path="'search.svg'"/>
                </div>
                <SearchInput v-else v-model="searchValue" class="search-input" :search-list="searchList" @click.stop @search="startSearch"/>
            </template>
            <template v-else>
                <h4 class="mob-tablet-h2">Dashboard</h4>
            </template>
        </div>
        <div class="list" :class="{show: filterIsOpen}" @click.stop>
            <template v-for="(item, i) in localFilters" :key="i">
                <TimePicker v-if="item.type === 'time'" v-model="selectData['time']" @update="(time) => setTime(time, item)"/>
                <BaseSelectBox
                    v-if="item.type === 'select'"
                    v-model="selectData[item.field]"
                    :options="
                    Object.entries(item.data).map(([key, value]) => ({
                        value: key,
                        title: value,
                        key: item.field
                    }))
                "
                    :placeholder="selectData[item.field] ? selectData[item.field].title.toString() : item.placeholder"
                    :width="260"
                    @update="selectHandle"
                >
                </BaseSelectBox>
                <BaseMultiselect
                    v-else-if="item.type === 'multiselect'"
                    :placeholder="item.placeholder"
                    :search="item.search"
                    :selected-values="filtersData[item.field] || []"
                    :multiselect="item.multiselect"
                    :field="item.field"
                    :options="
                    Object.entries(item.data).map(([key, value]) => ({
                        key: !isNaN(key) && key.trim() !== '' ? parseInt(key) : key,
                        value: value
                    }))
                "
                    :width="260"
                    @change="multiselectHandle"
                />
                <template v-else-if="item.type === 'radio'">
                    <p class="typo-heading-small-semi-bold-gray">{{ item.placeholder }}</p>
                    <BaseToggle/>
                </template>
            </template>
            <template v-if="levels">
                <BaseSelectBox
                    v-for="(item, index) in computedLevelsFields"
                    :key="item.key"
                    :placeholder="selectData[index] ? selectData[index].title?.toString() : item.placeholder"
                    :width="260"
                    :options="levelOptions"
                    @click="openLevel(index)"
                    @update="level => setLevels(level, index)"
                />
            </template>
            <div v-if="localFilters.length" class="action">
                <BaseButton
                    :text="buttonText" :height="40" :width="230" :disable="!(!!Object.keys(filtersData).length)"
                    @click="search"/>
                <BaseClear v-if="(!!Object.keys(filtersData).length)" @click.stop="debouncedClearFilters()"/>
            </div>
        </div>
    </div>
</template>

<style scoped>
.filters-list {
    position: relative;
}

.list {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
}

.action {
    display: flex;
    align-items: center;
    gap: 20px;
}

.header {
    display: none;
}

.filter-icon {
    opacity: 0;
    display: none;
}

@media (max-width: 767px) {
    .filters-list {
        padding-bottom: 16px;
        border-bottom: 1px solid var(--white-08);
    }

    .header.dashboard {
        flex-direction: row-reverse;
    }

    .filters-list.exist {
        opacity: 1;
        display: block;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 12px;
    }

    .filter-icon.active {
        background-color: var(--purple);
    }

    .search-icon,
    .filter-icon.exist {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background-color: transparent;
        transition: all .3s ease-in-out;
        cursor: pointer;
        opacity: 1;
    }

    .list {
        display: none;
    }

    .list.show {
        display: flex;
        background-color: var(--gray-900);
        flex-direction: column;
        padding: 24px;
        position: absolute;
        top: 40px;
        left: 0;
        z-index: 10;
        border-radius: 8px;
    }

    .button-box.fill {
        width: 100px !important;
        grid-area: 1/2;
    }

    .action {
        display: grid;
        align-items: center;
        gap: 20px;
        grid-template-columns: 1fr max-content;
        width: 100%;
    }

    .header {
        padding: 0;
    }
}

@media (max-width: 360px) {
    .header,
    .search-input {
        width: 100% !important;
    }
}
</style>
