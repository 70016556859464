<script setup>
import {ref, defineEmits, defineProps, computed, defineModel, watch} from 'vue'
import {BaseInput} from '@/shared/ui/Inputs'
import {BaseImage} from '@/shared/ui/Images'
import {SessionModel} from "@/entities/Session"

const emits = defineEmits(['update', 'update:modelValue'])
const [value] = defineModel()

const props = defineProps({
    multiselect: {
        type: Boolean,
        default: false
    },
    options: {
        type: Array,
        default: () => [],
        validator: (options) => {
            return options.every((option) => option.title && (option.value || option.value === null))
        }
    },
    width: {
        type: Number
    },
    disable: {
        type: Boolean,
        default: false
    },
    placement: {
        type: String,
        default: 'bottom'
    },
    design: {
        type: String
    },
    placeholder: {
        type: String
    },
    label: {
        type: String
    },
    error: {
        type: Boolean,
        default: false
    },
    isSorted: {
        type: Boolean,
        default: true
    }
})

const sessionStore = SessionModel.useSessionStore()

const SEARCH_VISIBLE_FROM = 10
const searchValue = ref('')
const selectRef = ref(null)
const title = ref('')
const selectIsOpen = ref(false)
const selectedOption = ref(null)

const select = (option) => {
    toggleDropdown()
    selectedOption.value = option
    title.value = option.title
    emits('update:modelValue', option.value)
    emits('update', selectedOption.value)
}

const toggleDropdown = () => {
    if (props.disable) {
        return
    }

    selectIsOpen.value = !selectIsOpen.value
    searchValue.value = ''
    document.removeEventListener('click', closeAllSelects)
}

const onOverlayAfterEnter = () => {
    document.addEventListener('click', closeAllSelects)
}

const closeAllSelects = () => {
    if (selectIsOpen.value) {
        selectIsOpen.value = false
    }
    document.removeEventListener('click', closeAllSelects)
}

const filteredOptions = computed(() => {
    let options = [...props.options]

    if (props.isSorted) {
        options.sort((a, b) => {
            const numA = parseInt(a.title, 10)
            const numB = parseInt(b.title, 10)

            if (!isNaN(numA) && !isNaN(numB)) {
                return numA - numB
            }

            return a.title.localeCompare(b.title)
        })
    }

    if (searchValue.value) {
        const loweredSearchValue = searchValue.value.toLowerCase()

        options = options.filter(
            (option) =>
                option.value.toLowerCase().includes(loweredSearchValue) ||
                option.title.toLowerCase().includes(loweredSearchValue)
        )
    }

    return options
})

const getPlaceholder = computed(() => {
    return findTitle(value.value) || props.placeholder
})

const findTitle = (value) => {
    const matchedValue = props.options.find(el => el.value === value)
    if (matchedValue) {
        return matchedValue.title
    }

    return props.placeholder
}

const getOptionClasses = (option) => {
    return {
        active: value.value?.value === option.value || value.value == option.value,
        red: typeof option.value === 'string' && option.value.split('_')[0] === 'delete'
    }
}

const autoSearchVisibility = computed(() => {
    return Object.keys(props.options).length > SEARCH_VISIBLE_FROM
})

const removeAll = () => {
    if (selectedOption.value) {
        emits('update', {
            key: selectedOption.value.key,
            value: ''
        })
        selectedOption.value = null
        title.value = ''
    }
}

watch(
    () => sessionStore.clearFilters,
    () => {
        removeAll()
    },
    {
        deep: true
    }
)
</script>

<template>
    <div
        ref="selectRef"
        class="select"
        :class="{ [placement]: placement, [design]: design, error: error, open: selectIsOpen }"
        :style="{ width: width ? width + 'px' : '' }"
        @click="toggleDropdown()"
    >
        <label v-if="label">{{ label }}</label>
        <slot name="label"></slot>
        <div class="placeholder-box">
            <span class="placeholder">
                <slot name="placeholder">{{ getPlaceholder }}</slot>
            </span>
            <BaseImage v-if="!disable" :width="11" :height="6" :path="'arrow.svg'" class="arrow"/>
        </div>
        <Transition name="select-fade" @after-enter="onOverlayAfterEnter">
            <div v-if="selectIsOpen" class="select-dropdown" @click.stop>
                <div class="fixed-bar">
                    <div v-if="autoSearchVisibility" class="search" @click.stop>
                        <BaseInput v-model="searchValue" :placeholder="'Search'" :design="'no-border'"/>
                    </div>
                </div>
                <ul>
                    <li
                        v-for="(option, i) in filteredOptions"
                        :key="i"
                        class="option"
                        :class="getOptionClasses(option)"
                        @click="select(option)"
                    >
                        <slot :option="option" name="option">{{ option.title }}</slot>
                    </li>
                </ul>
            </div>
        </Transition>
    </div>
</template>

<!--todo move base styles into .css-->
<style scoped>
.select {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
}

.select.open::after {
    content: '';
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1;
    background: transparent;
    cursor: default;
}

.select > * {
    filter: blur(0px);
}

.select.open .arrow {
    transform: rotate(180deg);
    transform-origin: center center;
}

.select-dropdown {
    position: absolute;
    width: 100%;
    left: 0;
    transition: all 0.2s ease-in-out;
    border-radius: var(--general-border-radius);
    background: var(--gray-700);
    max-height: 250px;
    overflow-y: auto;
    outline: none;
    z-index: 2;
    margin-top: 10px;
}

.placeholder-box {
    color: var(--white-064);
    font-weight: var(--general-font-weight);
    padding: 10px;
    position: relative;
    width: 100%;
    background: var(--gray-600);
    border-radius: var(--general-border-radius);
    white-space: nowrap;
    display: inline-grid;
    grid-template-columns: 1fr max-content;
    gap: 10px;
    place-items: center;
    height: 40px;
    text-transform: capitalize;
}

.placeholder {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.arrow {
    transition: transform 0.4s ease-in-out;
}

.fixed-bar {
    width: 100%;
}

.options p:last-child {
    border: none;
    padding-right: 0;
}

ul {
    overflow: hidden;
}

ul li {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--primary-white);
}

ul li.active {
    background-color: var(--white-08);
}

ul li:hover {
    background-color: var(--white-08);
}

.select.open .placeholder::after {
    transform: translateY(-50%) rotate(180deg);
}

.select.top .select-dropdown {
    bottom: 0;
}

.select.open.top .select-dropdown {
    transform: translateY(10px);
    bottom: 50px;
}

option:first-child {
    padding-top: 10px;
}

.option:last-child {
    margin-bottom: 0;
    padding-bottom: 10px;
    border: none;
}

.option.active {
    background-color: var(--white-08);
}

.option.red {
    color: var(--light-red);
}

.input-box {
    width: 100%;
}

.select.outline-select .placeholder-box {
    background: var(--gray-600);
    color: var(--white);
}

.select.outline-select .select-dropdown {
    background: var(--gray-800);
}

/*table select*/
.select.table-select {
    padding: 0;
    background: transparent;
}

.select.table-select .placeholder-box {
    background-color: transparent;
    color: var(--primary-white);
    font-weight: var(--general-font-weight);
    padding: 0;
}

.select.table-select .select-dropdown {
    background-color: var(--gray-900);
    border-radius: 0 0 var(--general-border-radius) var(--general-border-radius);
}

.load-template .select-dropdown {
    background-color: var(--background);
    height: 75px;
}

.load-template li {
    background-color: transparent;
    border-bottom: 1px solid var(--white-08);
}

.load-template li:last-child {
    border-bottom: 0;
}

.load-template li:hover {
    background-color: transparent;
}

.select.pagination .placeholder-box {
    background-color: transparent;
    border-bottom: var(--general-border) solid var(--white-024);
    border-radius: 0;
    gap: 10px;
}

.select.pagination .placeholder {
    font-size: 12px;
}

.select.pagination .select-dropdown {
    top: 20px;
    border-top: var(--general-border) solid var(--white);
    border-radius: 0 0 var(--general-border-radius) var(--general-border-radius);
}

.select.pagination .placeholder {
    width: 30px;
    color: var(--white);
}

@media (max-width: 767px) {
    .select.outline-select ul li {
        padding: 13px 10px;
    }

    ul li {
        font-size: 12px;
    }
}
</style>
