<script setup>
import {BaseModal} from "@/shared/ui/Modal"
import {computed} from "vue"

import {ModalModel} from "@/entities/Modal"
import {typesList} from "@/shared/data"

const modalStore = ModalModel.useModalStore()

const modalData = computed(() => modalStore.modalData)

const getConfirmationTitle = () => {
    switch (modalData.value.option) {
        case 'change_settings':
            return 'Success'
        case 'callStatus':
            return `Update Lead Status`
        case 'status':
            return `Update Status`
        case 'type':
            return `Mark as ${typesList[modalData.value.action]}`
        case 'note':
            return `Update Note`
        case 'delete_template':
            return `Delete Template`
        case 'delete_lead':
            return `Delete Lead`
        case 'delete_user':
            return `Delete User`
        case 'delete_mass_leads':
            return `Delete Lead(s)`
        case 'delete_brand':
            return 'Delete Brand'
        case 'delete_mass_brands':
            return 'Delete Brand(s)'
        case 'delete_source':
            return 'Delete Source'
        case 'delete_mass_sources':
            return 'Delete Source(s)'
        case 'delete_partner':
            return 'Delete Partner'
        case 'delete_mass_partners':
            return 'Delete Partner(s)'
        case 'delete_mass_users':
            return 'Delete User(s)'
        case 'duplicate_brand':
            return 'Duplicate brand'
        case 'duplicate_partner':
            return 'Duplicate partners'
        case 'duplicate_source':
            return 'Duplicate source'
        case 'block_duplicate_email':
            return 'Block duplicate e-mail'
        case 'block_duplicate_phone':
            return 'Block duplicate phone'
        case 'block_duplicate_ip':
            return 'Block duplicate IP'
        default:
            return ''
    }
}

const getConfirmationDescription = () => {
    switch (modalData.value.option) {
        case 'change_settings':
            return 'You’ve changed your settings successfully'
        case 'callStatus':
            return `You’ve successfully changed LEAD`
        case 'status':
            return `You’ve successfully changed`
        case 'type':
            return `You’ve successfully changed LEAD to regular mode`
        case 'note':
            return `You’ve successfully changed`
        case 'delete_template':
            return `You’ve successfully delete template`
        case 'delete_lead':
            return `You’ve successfully delete LEAD`
        case 'delete_user':
            return `You’ve successfully delete user`
        case 'delete_row':
            return `You’ve successfully delete row`
        case 'delete_mass_leads':
            return `You’ve successfully delete leads`
        case 'delete_brand':
            return 'You’ve successfully delete brand'
        case 'delete_mass_brands':
            return 'You’ve successfully delete brands '
        case 'delete_source':
            return 'You’ve successfully delete source'
        case 'delete_mass_sources':
            return 'You’ve successfully delete sources'
        case 'delete_partner':
            return 'You’ve successfully delete partner'
        case 'delete_mass_partners':
            return 'You’ve successfully delete partners'
        case 'delete_mass_users':
            return 'You’ve successfully delete users'
        case 'delete_mass_rows':
            return 'You’ve successfully delete rows'
        case 'duplicate_brand':
            return 'You’ve successfully duplicate brand to regular mode'
        case 'duplicate_source':
            return 'You’ve successfully duplicated source'
        case 'duplicate_partner':
            return 'You’ve successfully duplicated partners'
        default:
            return 'Successfully updated'
    }
}
</script>

<template>
    <div class="successful-modal">
        <BaseModal >
            <template #title>
                <h4 class="typo-heading-h4">{{ getConfirmationTitle() }}</h4>
            </template>
            <template #content>
                <p class="description">
                    {{ getConfirmationDescription() }}
                </p>
            </template>
        </BaseModal>
    </div>
</template>

<style scoped>
.description {
    max-width: 240px;
    text-align: center;
    margin: 0 auto 0;
}
</style>
