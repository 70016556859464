<script setup>
import depositsPageConfig from '@/pages/deposits/configs/deposits-config'

import {DataTable, FiltersList} from '@/features/Datatable'
import {PaginationBox, TableActions} from '@/features/Datatable'
import {PageTitle} from '@/shared/ui/Page'
import {usePageTitle} from '@/shared/lib/use/usePageTitle'

import {DepositsModel} from '@/entities/Deposits'
import {depositsStatusesList} from "@/shared/data/depositsStatusesList"
import {provide} from "vue";

const {cols, searchByList, actionList, tableActions, tableId, pathDt, initOrder, filters} =
    depositsPageConfig.config()

const title = usePageTitle('Deposits')

const depositsStore = DepositsModel.useDepositsStore()

const getWeekRange = () => {
    const today = new Date()

    const startOfWeek = new Date(today)
    startOfWeek.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1))
    startOfWeek.setHours(0, 0, 0, 0)

    const endOfWeek = new Date(today)
    endOfWeek.setHours(23, 59, 59, 999)

    return [Math.floor(startOfWeek.getTime()),
        Math.floor(endOfWeek.getTime())]
}

provide('timeRange', getWeekRange())

const filterBy = {
    field: 'timeDeposit',
    data: {
        $gt: getWeekRange()[0] / 1000,
        $lte: getWeekRange()[1] / 1000
    }
}

const approveDeposit = (row) => {
    depositsStore.approveDeposit(row.id.data, 3)
}
</script>

<template>
    <div class="leads-page container">
        <div class="content">
            <PageTitle :title="'Deposits'" :icon="'page-icons/deposits.svg'"/>
            <DataTable
                :fields="cols"

                :path="pathDt"
                :table-id="tableId"
                :order="initOrder"
                :option-list="tableActions"
                :action-list="actionList"
                :filter-by="filterBy"
                @approve="approveDeposit"
            >
                <template #center-right>
                    <TableActions :search-list="searchByList"/>
                </template>
                <template #top-left>
                    <FiltersList
                        :leave-filter="leaveField" :search-list="searchByList" :filters="filters"
                        :button-text="'Search'"/>
                </template>
                <template #bottom-left>
                    <PaginationBox/>
                </template>
                <template #[`status`]="{ elData }">
                    <span class="deposit-status" :class="{green: elData === 1 || elData === 3}">
                        {{ depositsStatusesList[elData] }}
                    </span>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<style scoped>
.deposit-status {
    color: var(--white);
    font-size: 13px;
    font-weight: 500;
}

.green {
    color: #71D275;
}
</style>
