import {CreateBrandModal, CreateAffiliateModal, EditBrandAffiliateModal} from '@/features/Brands'

import {BrandPage} from "@/pages/brand"
import {SourcePage} from "@/pages/source"

import {CreateSourceModal, CreateSourceRowModal, EditSourceRowModal, EditSourceModal} from '@/features/Sources'

import {
    CreatePartnerModal,
    CreatePartnerBrandModal,
    EditPartnerBrandModal,
    GetPartnerDetailsModal
} from '@/features/Partners'

import {CreateUserModal} from '@/features/Users'

import {LoadTemplateModal, SaveTemplateModal} from '@/features/Datatable'

import {ConfirmationModal} from '@/features/ConfirmationModal/ui'

import {ChangeProfileModal} from '@/features/User/ChangeProfileModal'

import {EditLeadsModal} from '@/features/Leads'
import {CallStatusInfoModal} from '@/features/Leads/CallStatusInfoModal'

import {SuccessfulModal} from "@/features/SuccessfulModal"

import {InfoModal} from '@/features/Reports'
import {FtdsModal} from '@/features/Reports'

import {PartnerPage} from "@/pages/partner"

export const modalList = {
    createBrand: CreateBrandModal,
    createSource: CreateSourceModal,
    createAffiliate: CreateAffiliateModal,
    editBrandAffiliate: EditBrandAffiliateModal,
    createPartner: CreatePartnerModal,
    createPartnerBrand: CreatePartnerBrandModal,
    editPartnerBrand: EditPartnerBrandModal,
    getPartnerDetails: GetPartnerDetailsModal,
    createSourceRow: CreateSourceRowModal,
    editSource: EditSourceModal,
    editSourceRow: EditSourceRowModal,
    createUser: CreateUserModal,
    loadTemplate: LoadTemplateModal,
    saveTemplate: SaveTemplateModal,
    confirmation: ConfirmationModal,
    changeProfile: ChangeProfileModal,
    editLeads: EditLeadsModal,
    leadCallStatusInfoModal: CallStatusInfoModal,
    successful: SuccessfulModal,
    reportInfoModal: InfoModal,
    reportFtdsModal: FtdsModal,
    brandModal: BrandPage,
    sourceModal: SourcePage,
    partnerModal: PartnerPage
}
