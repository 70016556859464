import {computed} from "vue"

export default {
    config: () => {
        return {
            tableId: 'reports-page',
            path: 'reports/general',
            levels: {
                'brandId': "Brand name",
                'country': "Country",
                'sourceId': "Source Name"
            },
            levelsFields: [
                {
                    field: 'firstLevel',
                    placeholder: 'First Level',
                    type: 'select',
                },
                {
                    field: 'secondLevel',
                    placeholder: 'Second Level',
                    type: 'select',
                },
                {
                    field: 'thirdLevel',
                    placeholder: 'Third Level',
                    type: 'select',
                },
            ],
            cols: computed(() => [
                {
                    label: 'Name',
                    key: 'name',
                    sortable: true
                },
                {
                    label: 'Leads',
                    key: 'leads',
                    sortable: true,
                    type: "func",
                    func: (data, elData) => {
                        return elData
                    }
                },
                {
                    label: 'failures',
                    key: 'failures',
                    sortable: true,
                    type: "func",
                    func: (data, elData) => {
                        return elData
                    }
                },
                {
                    label: 'FTDs',
                    key: 'ftds',
                    sortable: true,
                    type: "func",
                    func: (data, elData) => {
                        return elData
                    }
                },
                {
                    label: 'Live FTDs',
                    key: 'liveFtds',
                    sortable: true,
                    type: "func",
                    func: (data, elData) => {
                        return elData
                    }
                },
                {
                    label: 'Late FTDs',
                    key: 'lateFtds',
                    sortable: true,
                    type: "func",
                    func: (data, elData) => {
                        return elData
                    }
                },
                {
                    label: 'Soft Failures',
                    key: 'softFailur',
                    sortable: true,
                    type: "func",
                    func: (data, elData) => {
                        return elData
                    }
                },
                {
                    label: 'Hard Failures',
                    key: 'hardFailur',
                    sortable: true,
                    type: "func",
                    func: (data, elData) => {
                        return elData
                    }
                },
                {
                    label: 'Injected',
                    key: 'injected',
                    sortable: true,
                    type: "func",
                    func: (data, elData) => {
                        return elData
                    }
                },
                {
                    label: 'CR',
                    key: 'cr',
                    sortable: true,
                    type: "func",
                    func: (data, elData) => {
                        return elData.toFixed(1) + '%'
                    }
                },
                {
                    label: 'Late CR',
                    key: 'lateCr',
                    sortable: true,
                    type: "func",
                    func: (data, elData) => {
                        return elData !== null ? elData.toFixed(1) + '%' : '0.0%'
                    }
                },
            ]),
            filters: computed(() => [
                {
                    field: 'timeCreate',
                    placeholder: 'Data Range',
                    type: 'time',
                }
            ]),
            searchByList: computed(() => [
                {
                    key: 'firstname',
                    value: 'Name'
                }
            ]),
        }
    }
}
