import ReportsPage from "@/pages/reports"

export const route = {
    path: '/reports',
    name: 'reportsPage',
    component: ReportsPage,
    meta: {
        isPublic: true
    }
}
