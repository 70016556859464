<script setup>
import {computed, onMounted, shallowRef} from 'vue'
import { router } from '@/app/providers'

import config from '@/pages/partner/configs/partner.config'

const {tabs} = config.config()

import {BaseTabBox} from '@/shared/ui/Tabs'
import {BaseTabViewer, BaseTabViewerItem} from '@/shared/ui/Tabs'

import {ModalModel} from '@/entities/Modal'
import {PartnersModel} from '@/entities/Partners'
import {usePageTitle} from '@/shared/lib/use/usePageTitle'
import {SinglePageTitle} from "@/shared/ui/Page"
import {BaseBack} from "@/shared/ui/Actions"

onMounted(() => {
    if (!partnersStore.partner) {
        partnersStore.getPartner(partnerID).then(() => {
            title = usePageTitle(partner.value['firstname'].data + ' ' + partner.value['lastname'].data)
        })
    }
})

const modalStore = ModalModel.useModalStore()
const partnersStore = PartnersModel.usePartnersStore()

const partner = computed(() => partnersStore.partner)

let title

if (partner.value) {
    title = usePageTitle(partner.value['firstname'].data + ' ' + partner.value['lastname'].data)
}

const activeTab = shallowRef(tabs[0])
const partnerID = router.currentRoute.value.params.id

const availableActionPartners = ['entitles', 'brands', 'paymentPlan', 'restrictions']
const actionAvailable = computed(() => availableActionPartners.includes(activeTab.value.key))

const setActiveTab = (tab) => {
    activeTab.value = tab
}

const makeAction = () => {
    switch (activeTab.value.key) {
        case 'entitles':
            modalStore.setModal('createEntity')
            break
        case 'brands':
            modalStore.setModal('createPartnerBrand')
            break
        case 'paymentPlan':
            modalStore.setModal('createPlan')
            break
        case 'restrictions':
            break
        default:
            break
    }
}
</script>

<template>
    <div class="single-page container">
        <SinglePageTitle
            :loaded="!!partner"
            :title="partner ? partner.firstname.data + ' ' + partner.lastname.data : ''"
            :action-available="actionAvailable"
            @action="makeAction"
        />
        <BaseBack :path-name="'partnersPage'" />
        <BaseTabBox :tabs="tabs" :active="activeTab" @change-tab="setActiveTab" />
        <BaseTabViewer>
            <template #content>
                <BaseTabViewerItem :load="!!partner" :component="activeTab.component" />
            </template>
        </BaseTabViewer>
    </div>
</template>

<style scoped>
.tabs {
    margin-top: 24px;
}

@media (max-width: 1000px) {
    button {
        display: none;
    }
}
</style>
