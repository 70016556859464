<script setup>
import {computed, ref} from 'vue'

import config from '@/pages/partner/configs/partner-settings-tab.config'

import {verticalsList, trafficSourceList} from '@/shared/data'

const {companyInputs, statusList} = config.config()

import {BaseInput, BaseToggle, BaseSelectBox, BaseTextarea} from '@/shared/ui/Inputs'
import {BaseButton} from '@/shared/ui/Buttons'

import {PartnersModel} from '@/entities/Partners'
import {useAppStore} from '@/app/providers'
import {SessionModel} from '@/entities/Session'
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {BaseBorderPanel} from '@/shared/ui/Panels'
import {countriesList} from "@/shared/data/countriesList"
import {checkOnRequiredFields, isValidEmail} from "@/shared/lib/utils/validation"
import {isMobile} from "@/entities/Sources/model"
import {ModalModel} from '@/entities/Modal'
import {UserModel} from '@/entities/User'
import {useRouter} from "vue-router"

const partnersStore = PartnersModel.usePartnersStore()
const appStore = useAppStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const sessionStore = SessionModel.useSessionStore()
const modalStore = ModalModel.useModalStore()
const userStore = UserModel.useUserStore()

const router = useRouter()

const partner = computed(() => partnersStore.partner)
const sources = computed(() => SessionModel.getSources(sessionStore))
const users = computed(() => SessionModel.getUsers(sessionStore))
const user = computed(() => userStore.user)

const email = ref(partner.value['email'].data)
const firstname = ref(partner.value['firstname'].data)
const lastname = ref(partner.value['lastname'].data)
const phone = ref(partner.value['phone'].data)
const skype = ref(partner.value['skype'].data)
const telegram = ref(partner.value['telegram'].data)
const domain = ref(partner.value['domain'].data)

const status = ref(partner.value['status'].data)
const trafficSource = ref(partner.value['trafficSource'].data)
const country = ref(partner.value['country'].data)
const vertical = ref(partner.value['vertical'].data)
const distribution = ref(partner.value['distribution'].data)
const validationPhone = ref(partner.value['validationPhone'].data)
const validationEmail = ref(partner.value['validationEmail'].data)
const blockDuplicateIP = ref(partner.value['blockDuplicateIP'].data)
const blockDuplicatePhone = ref(partner.value['blockDuplicatePhone'].data)
const blockDuplicateEmail = ref(partner.value['blockDuplicateEmail'].data)
const sourceIdDefault = ref(partner.value['sourceIdDefault'].data)
const note = ref(partner.value['note'].data)
const userIdCreated = ref(partner.value['userIdCreated']?.data || '')

const saving = ref(false)

const requireFields = ref({
    email: {data: email, error: false, type: 'email'},
    firstname: {data: firstname, error: false},
    lastname: {data: lastname, error: false},
})

const close = () => {
    router.push({name: "partnersPage"})
}

const save = () => {
    if (!checkOnRequiredFields(requireFields.value)) {
        generalNotificationsStore.showError({
            message: 'Fill all required fields.'
        })
        return
    }

    if (!isValidEmail(email.value)) {
        generalNotificationsStore.showError({
            message: 'Invalid Email'
        })
        requireFields.value['email'].error = true
        return
    }

    saving.value = true
    appStore
        .update(
            {
                email: email.value,
                firstname: firstname.value,
                lastname: lastname.value,
                phone: phone.value,
                telegram: telegram.value,
                skype: skype.value,
                domain: domain.value,
                ...Object.fromEntries(companyInputs.value.map((item) => [item.key, item.value])),
                status: status.value,
                trafficSource: trafficSource.value,
                country: country.value,
                vertical: vertical.value,
                distribution: distribution.value,
                validationEmail: validationEmail.value,
                validationPhone: validationPhone.value,
                blockDuplicatePhone: blockDuplicatePhone.value,
                blockDuplicateIP: blockDuplicateIP.value,
                blockDuplicateEmail: blockDuplicateEmail.value,
                sourceIdDefault: sourceIdDefault.value,
                note: note.value,
                userIdCreated: userIdCreated.value
            },
            partner.value,
            'partners-table'
        )
        .finally(() => {
            if (isMobile()) {
                modalStore.setModal('successful')
                modalStore.setModalData({
                    option: 'change_settings',
                })
            } else {
                generalNotificationsStore.showSuccess({
                    title: 'Changes Applied:',
                    message: 'You’ve changed your settings successfully'
                })
            }
            appStore.getList('partners')
        })
        .finally(() => {
            saving.value = false
        })
}
</script>

<template>
    <div class="setting-tab">
        <BaseBorderPanel>
            <template #title>
                <h2 class="mob-tablet-button">General information</h2>
            </template>
            <template #content>
                <div class="row">
                    <BaseInput
                        v-model="email"
                        :error="requireFields['email'].error"
                        :placeholder="'Enter e-mail'"
                        :label="'E-mail*'"
                    />
                    <BaseInput
                        v-model="firstname"
                        :error="requireFields['firstname'].error"
                        :placeholder="'Enter first name'"
                        :label="'First name*'"
                    />
                    <BaseInput
                        v-model="lastname"
                        :error="requireFields['lastname'].error"
                        :placeholder="'Enter last name'"
                        :label="'Last name*'"
                    />
                    <BaseInput
                        v-model="phone"
                        :placeholder="'Enter phone number'"
                        :label="'Phone number'"
                    />
                    <BaseInput
                        v-model="skype"
                        :placeholder="'Enter skype'"
                        :label="'Skype'"
                    />
                    <BaseInput
                        v-model="telegram"
                        :placeholder="'Enter telegram'"
                        :label="'Telegram'"
                    />
                    <BaseInput
                        v-model="domain"
                        :placeholder="'Enter domain'"
                        :label="'Domain'"
                    />
                    <BaseSelectBox
                        v-model="trafficSource"
                        :options="
                            Object.entries(trafficSourceList).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                                "
                        :label="'Traffic source'"
                        :placeholder="trafficSource || 'Select traffic source'"
                    />
                    <BaseSelectBox
                        v-model="country"
                        :options="
                            Object.entries(countriesList()).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                                "
                        :label="'Country'"
                        :placeholder="countriesList()[country] || 'Select country'"
                    />
                    <BaseSelectBox
                        v-model="vertical"
                        :label="'Vertical'"
                        :options="
                            Object.entries(verticalsList).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                                "
                        :placeholder="verticalsList[vertical] || 'Select vertical'"
                    />
                </div>
                <div class="switch-container distribution">
                    <h5 class="mob-tablet-h8">Distribution affiliate</h5>
                    <div class="switch-content">
                        <BaseToggle :value="distribution" @change="(value) => (distribution = value)"/>
                        {{ distribution ? 'On' : 'Off' }}
                    </div>
                </div>
            </template>
        </BaseBorderPanel>
        <BaseBorderPanel>
            <template #title>
                <h2 class="typo-mob-table-button">Owner</h2>
            </template>
            <template #content>
                <div class="row mb-24">
                    <BaseSelectBox
                        v-model="userIdCreated"
                        :disable="user && user['permissions'].indexOf('admin') < 0"
                        :options="
                            Object.entries(users).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                        :label="'Owner of the partner*'"
                        :placeholder="users[userIdCreated] || 'Select owner'"
                    />
                </div>
                <h2 class="typo-mob-table-button mb-16">Status</h2>
                <div class="row mb-24">
                    <BaseSelectBox
                        v-model="status"
                        :options="
                            Object.entries(statusList).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                        :label="'Status of the partner*'"
                        :placeholder="statusList[status] || 'Select owner'"
                    />
                </div>
                <h2 class="typo-mob-table-button mb-16">Default source</h2>
                <div class="row mb-24">
                    <BaseSelectBox
                        v-if="sources"
                        v-model="sourceIdDefault"
                        :options="
                            Object.entries(sources).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                        :placeholder="sources[sourceIdDefault] || 'Select default source'"
                    >
                        <template #label>
                            <label class="default-source">This is where traffic from the partner will go, unless you
                                choose otherwise</label>
                        </template>
                    </BaseSelectBox>
                </div>
                <h2 class="typo-mob-table-button mb-16">Company</h2>
                <div class="row">
                    <template v-for="input in companyInputs" :key="input.key">
                        <BaseInput v-model="input.value" :label="input.label" :placeholder="input.placeholder"/>
                    </template>
                </div>
            </template>
        </BaseBorderPanel>
        <BaseBorderPanel>
            <template #title>
                <h2 class="typo-mob-table-button">Duplicate fields</h2>
            </template>
            <template #content>
                <div class="row">
                    <div class="duplicates">
                        <div class="switch-container">
                            <div class="left">
                                <h6 class="typo-mob-table-h8">Duplicate e-mail</h6>
                                <p class="typo-mob-tablet-h9 gray">Block leads with duplicate email</p>
                            </div>
                            <div class="switch-content">
                                <BaseToggle :value="blockDuplicateEmail" @change="(value) => (blockDuplicateEmail = value)"/>
                                {{ blockDuplicateEmail ? 'On' : 'Off' }}
                            </div>
                        </div>
                        <div class="switch-container">
                            <div class="left">
                                <h6 class="typo-mob-table-h8">Duplicate phone</h6>
                                <p class="typo-mob-tablet-h9 gray">Block leads with duplicate phone</p>
                            </div>
                            <div class="switch-content">
                                <BaseToggle :value="blockDuplicatePhone" @change="(value) => (blockDuplicatePhone = value)"/>
                                {{ blockDuplicatePhone ? 'On' : 'Off' }}
                            </div>
                        </div>
                        <div class="switch-container">
                            <div class="left">
                                <h6 class="typo-mob-table-h8">Duplicate ip</h6>
                                <p class="typo-mob-tablet-h9 gray">
                                    If enabled, any lead for this affiliate that shares the same IP as a previous lead in the
                                    last 20 minutes will be blocked
                                </p>
                            </div>
                            <div class="switch-content">
                                <BaseToggle :value="blockDuplicateIP" @change="(value) => (blockDuplicateIP = value)"/>
                                {{ blockDuplicateIP ? 'On' : 'Off' }}
                            </div>
                        </div>
                        <div class="switch-container">
                            <div class="left">
                                <h6 class="typo-mob-table-h8">Email validation</h6>
                                <p class="typo-mob-tablet-h9 gray">Block leads with invalid email (using dedicated API)</p>
                            </div>
                            <div class="switch-content">
                                <BaseToggle :value="validationEmail" @change="(value) => (validationEmail = value)"/>
                                {{ validationEmail ? 'On' : 'Off' }}
                            </div>
                        </div>
                        <div class="switch-container">
                            <div class="left">
                                <h6 class="typo-mob-table-h8">Phone validation</h6>
                                <p class="typo-mob-tablet-h9 gray">Block leads with invalid phone (using dedicated API)</p>
                            </div>
                            <div class="switch-content">
                                <BaseToggle :value="validationPhone" @change="(value) => (validationPhone = value)"/>
                                {{ validationPhone ? 'On' : 'Off' }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </BaseBorderPanel>
        <BaseBorderPanel>
            <template #title>
                <h2 class="typo-mob-table-button">Note</h2>
            </template>
            <template #content>
                <div class="row">
                    <BaseTextarea
                        v-model="note"
                        :height="90"
                        :label="'Note'"
                        :placeholder="'Enter note'"
                    />
                </div>
            </template>
        </BaseBorderPanel>
        <div class="row">
            <div class="save">
                <BaseButton :design="'outline'" :height="40" :text="'Cancel'" @click="close()"/>
                <BaseButton :height="40" :text="'Save'" :load="saving" @click="save()"/>
            </div>
        </div>
    </div>
</template>
<style scoped>
.select-box,
.input-box {
    width: 100%;
}

.row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.typo-mob-table-h9.gray {
    color: var(--white-048);
}

.switch-container {
    display: flex;
    align-items: center;
    margin-top: 8px;
    gap: 12px;
    justify-content: space-between;
}

.left p {
    margin-top: 4px;
}

.switch-container:first-child {
    margin-top: 0;
}

.switch-content {
    display: flex;
    align-items: center;
    gap: 4px;
}

.switch-container.distribution {
    justify-content: inherit;
}

.save {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.save button {
    width: 100% !important;
}

@media (max-width: 1024px) {
    .row {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .row {
        grid-template-columns: 1fr;
    }
}
</style>
