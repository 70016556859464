<script setup>
import {computed, onMounted, ref} from 'vue'
import {router} from '@/app/providers'
import config from '@/pages/source/configs/source.config'

const {cols, filters, tableActions, searchList, actionList, actions} = config.config()

import {capTypesList} from '@/shared/data'

import {DataTable} from '@/features/Datatable'
import {PaginationBox, FiltersList, TableActions} from '@/features/Datatable'

import {ModalModel} from '@/entities/Modal'
import {SourcesModel} from '@/entities/Sources'
import {usePageTitle} from '@/shared/lib/use/usePageTitle'
import {countriesList} from "@/shared/data/countriesList"
import {SinglePageTitle} from "@/shared/ui/Page"
import {BaseBack} from "@/shared/ui/Actions"

onMounted(() => {
    if (!source.value) {
        store.getSources().then(() => {
            store.getSource(sourceID).then(() => {
                title = usePageTitle(source.value['name'])
            })
        })
    }
})

const sourceID = router.currentRoute.value.params.id.toString()

const modalStore = ModalModel.useModalStore()
const store = SourcesModel.useSourcesStore()

const source = computed(() => store.source)
let title

if (source.value) {
    title = usePageTitle(source.value['name'])
}

const leaveField = {
    key: 'sourceId',
    data: sourceID
}

const filterBy = ref({
    field: 'sourceId',
    data: sourceID
})

const edit = (data) => {
    store.setRow(data)
    modalStore.setModal('editSourceRow')
}

const update = (action, item, data) => {
    item.selectIsOpen = false
    item.selectType = 0
    switch (action) {
        case 'edit':
            edit(data)
            break
        default:
            break
    }
}

const makeAction = () => {
    modalStore.setModal('createSourceRow')
}

const formattedCountries = (data) => {
    if (!data || !data.length) {
        return ''
    }

    const sortedCountriesArray = Object.entries(countriesList())
    const sortedCountriesObject = Object.fromEntries(sortedCountriesArray)
    const filteredData = data.filter((item) => sortedCountriesObject[item])
    const sortedData = filteredData.sort((a, b) => sortedCountriesObject[a].localeCompare(sortedCountriesObject[b]))
    return sortedData.map((i) => sortedCountriesObject[i]).join(', ')
}
</script>

<template>
    <div class="single-page container">
        <SinglePageTitle
            :title="source ? source.name : ''"
            :action-available="true"
            :loaded="!!source"
            @action="makeAction"
        />
        <BaseBack :path-name="'sourcesPage'"/>
        <DataTable
            :fields="cols"
            :path="'rows/dt'"
            :table-id="'rows-table'"
            :option-list="tableActions"
            :action-list="actionList"

            :filter-by="filterBy"
            :order="'numericId'"
            @action="update"
            @open="edit"
        >
            <template #top-left>
                <FiltersList :filters="filters" :search-list="searchList" :button-text="'Filter'" :leave-filter="leaveField"/>
            </template>
            <template #center-right>
                <TableActions :leave-fields="leaveField" :search-list="searchList" :actions="actions"/>
            </template>
            <template #bottom-left>
                <PaginationBox/>
            </template>

            <template #rowStatus="{ row }">
                <div class="row-status" :class="row['status'].data ? 'green' : 'red'">
                    {{ row['status'].data ? 'Enabled' : 'Disabled' }}
                </div>
            </template>

            <template #countries="{ elData }">
                <span>{{
                        elData && elData.length > 6 ? `${elData.length} countries` : formattedCountries(elData)
                    }}</span>
            </template>

            <template #capType="{ elData }">
                {{ capTypesList[elData] }}
            </template>
        </DataTable>
    </div>
</template>

<style scoped>
.single-page {
    overflow: auto;
}

.row-status {
    padding: 6px 8px;
    color: var(--primary-white);
    border-radius: 4px;
}

.row-status.red {
    background-color: var(--light-red);
}

.row-status.green {
    background-color: var(--green);
}

h4 {
    display: flex;
    align-items: center;
    gap: 16px;
}

@media (max-width: 1000px) {
    .filters-list {
        display: block;
    }
}
</style>
