<script setup>
import { BaseCheckBox } from '@/shared/ui/Inputs'
import { computed, defineModel, defineProps } from 'vue'
import { SessionModel } from '@/entities/Session'

const props = defineProps({
    design: {
        type: String,
        default: 'columns'
    }
})

const [permissions] = defineModel()

const sessionStore = SessionModel.useSessionStore()

const permissionsList = computed(() => SessionModel.getPermissions(sessionStore))

const checkPermissions = (index) => {
    const checked = isChecked(index)
    if (checked) {
        const indexToRemove = permissions.value.indexOf(index)
        permissions.value.splice(indexToRemove, 1)
    } else {
        permissions.value.push(index)
    }
}

const isChecked = (index) => {
    return permissions.value.includes(index)
}
</script>

<template>
    <div class="permissions-list" :class="[design]">
        <template v-for="(item, index) in permissionsList" :key="index">
            <div class="permission typo-mob-tablet-h9" @click="checkPermissions(index)">
                <BaseCheckBox
                    :value="isChecked(index)"
                    :checked="isChecked(index)"
                    @checked="checkPermissions(index)"
                />
                {{ item }}
            </div>
        </template>
    </div>
</template>

<style scoped>
.permissions-list.columns {
    grid-template-columns: repeat(2, 1fr);
}

.permissions-list.rows {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.permissions-list {
    display: grid;
    text-transform: capitalize;
    gap: 20px;
}

.permission {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}

.permission:first-child {
    margin: 0;
}

@media (max-width: 768px) {
    .permission {
        padding: 11px 11px 11px 0;
    }
}
</style>
