import {computed} from 'vue'
import {SessionModel} from '@/entities/Session'
import {countries, countriesList} from "@/shared/data/countriesList"

export default {
    config: () => {
        const sessionStore = SessionModel.useSessionStore()

        return {
            tableId: 'deposits-table',
            pathDt: 'deposits/dt',
            initOrder: 'lead.timeCreate',
            cols: computed(() => [
                {
                    label: '',
                    key: '-',
                    sortable: false
                },
                {
                    label: 'Register Date',
                    key: 'lead.timeCreate',
                    type: 'time',
                    sortable: true
                },
                {
                    label: 'Found Date',
                    key: 'lead.timeDeposit',
                    type: 'time',
                    sortable: true
                },
                {
                    label: 'Report Date',
                    key: 'timeDeposit',
                    type: 'time',
                    sortable: true
                },
                {
                    label: 'Email',
                    key: 'lead.email',
                    type: 'func',
                    sortable: true,
                    func: (data, elData) => {
                        return `<a class="email" href="mailto:${elData}">${elData}</a>`
                    }
                },
                {
                    label: 'Brands',
                    key: 'lead.brandId',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Affiliate',
                    key: 'lead.partnerId',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Unique ID',
                    key: 'lead.id',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Country',
                    key: 'lead.country',
                    type: 'func',
                    sortable: true,
                    func: (data, elData) => {
                        return countriesList()[elData]
                    }
                },
                {
                    label: 'Call Status',
                    key: 'lead.callStatus',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Note',
                    key: 'note',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Call Status Map',
                    key: 'lead.callStatusMapped',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Actions',
                    key: 'lead.action',
                    type: 'timer',
                    sortable: true
                },
                {
                    label: 'Deposit Status',
                    key: 'status',
                    type: 'string',
                    sortable: true
                }
            ]),
            filters: computed(() => [
                {
                    field: 'timeDeposit',
                    placeholder: 'Select Time Range',
                    type: 'time',
                },
                {
                    field: 'country',
                    placeholder: 'Select Countries',
                    type: 'multiselect',
                    data: countriesList()
                },
                {
                    field: 'brandId',
                    placeholder: 'Select Brands',
                    type: 'multiselect',
                    data: sessionStore.brands || []
                },
                {
                    field: 'partnerId',
                    placeholder: 'Select Affiliates',
                    type: 'multiselect',
                    data: sessionStore.partners || []
                },
            ]),
            searchByList: computed(() => [
                {
                    key: 'name',
                    value: 'Name'
                }
            ]),
            tableActions: computed(() => [
                {
                    key: 'select',
                    value: 'Select'
                }
            ]),
            actionList: computed(() => [
                {
                    key: 'action',
                    placeholder: 'Select An Action',
                    options: [{key: 'note', label: 'Change Note', length: 2}]
                },
                {
                    key: 'note',
                    placeholder: 'Change To...',
                    type: 0
                }
            ])
        }
    }
}
