import RestService from "@/shared/api/rest.service"
import {defineStore} from "pinia"
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {DatatableModel} from '@/entities/Datatable'
import {SessionModel} from '@/entities/Session'

export const useDepositsStore = defineStore('deposits', {
    state: () => ({}),
    actions: {
        approveDeposit(id, status) {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
            const datatableStore = DatatableModel.useDatatableStore()
            const sessionStore = SessionModel.useSessionStore()

            sessionStore.setTimeDepositFound(
                sessionStore.timeDepositsFound.filter(item => item.id !== id)
            )

            return new Promise((resolve, reject) => {
                RestService.put(`deposits/${id}`, {
                    status: status
                })
                    .then((ans) => {
                        resolve(ans.data.data)
                        datatableStore.getDatatableData()
                    })
                    .catch((errorObject) => {
                        generalNotificationsStore.showError(errorObject)
                    })
            })
        }
    }
})
