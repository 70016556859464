import { defineStore } from 'pinia'
import RestService from "@/shared/api/rest.service"

import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'

export const usestore = defineStore('leads', {
    state: () => ({
        lead: null
    }),
    actions: {
        setLead(data) {
            this.lead = data
        },
        getCallStatusInfo(id) {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

            return new Promise((resolve, reject) => {
                RestService.post('statuses/history', {
                    leadId: id,
                })
                    .then(ans => {
                        resolve(ans.data.data)
                    })
                    .catch((errorObject) => {
                        generalNotificationsStore.showError(errorObject)
                        reject(errorObject)
                    })
            })
        }
    }
})
