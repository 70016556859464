<script setup>
import {ref, computed, watch, provide, onMounted} from 'vue'

import {LineChart} from '@/shared/ui/Charts'
import {BaseSwitchBox} from '@/shared/ui/Inputs'
import {FiltersList} from '@/features/Datatable'
import {DashboardModel} from '@/entities/Dashboard'
import {SessionModel} from '@/entities/Session'

import config from '@/pages/dashboard/configs/dashboard.config'
import {usePageTitle} from '@/shared/lib/use/usePageTitle'
import {BaseLoader} from '@/shared/ui/Loaders'
import BaseDashboardDatatable from "@/widgets/DashboardDatatable/BaseDashboardDatatable.vue"
import {BaseImage} from "@/shared/ui/Images"
import {getBrands} from "@/entities/Session/model"

const {mainCols, brandFailuresCols, timesFilters, filters} = config.config()
const dashboardStore = DashboardModel.useDashboardStore()
const sessionStore = SessionModel.useSessionStore()

const dashboardData = computed(() => dashboardStore.dashboardData)
const brands = computed(() => getBrands(sessionStore))

const title = usePageTitle('Dashboard')

onMounted(() => {
    dashboardStore.setConfig(initConfig.value)
})

const time = ref([])
const startTime = ref([])
const period = ref('All Time')
const timeIsInit = ref(false)
provide('timeRange', time)
provide('startTime', startTime)

const convertOffsetToSeconds = (offset) => {
    return (offset * 60) * -1
}

const initConfig = ref({
    granularity: 86400,
    timeshift: convertOffsetToSeconds(sessionStore.offset !== undefined ? sessionStore.offset : new Date().getTimezoneOffset())
})

const currentConfig = ref({
    granularity: initConfig.value.granularity
})

const timeRange = ref({})
const setConfig = (data) => {
    const selectedPeriod = data.period || period.value
    period.value = selectedPeriod
    data.timeshift = convertOffsetToSeconds(sessionStore.offset !== undefined ? sessionStore.offset : new Date().getTimezoneOffset())

    if (selectedPeriod === 'Today' || selectedPeriod === 'Yesterday') {
        data.granularity = 3600
    } else {
        data.granularity = initConfig.value.granularity
    }

    if (data.from || data.to) {
        timeRange.value = {...data}
    }

    const updatedData = {...timeRange.value, ...data}
    currentConfig.value = updatedData

    if ((data.from || updatedData.from) && parseInt(sessionStore.offset) !== 0) {
        currentConfig.value.from = adjustForOffset(data.from || updatedData.from, 0)
        currentConfig.value.to = adjustForOffset(data.to || updatedData.to, 0)
    } else if (data.from || updatedData.from) {
        currentConfig.value.from = adjustForOffset(data.from || updatedData.from, new Date().getTimezoneOffset())
        currentConfig.value.to = adjustForOffset(data.to || updatedData.to, new Date().getTimezoneOffset())
    }
    delete updatedData.period

    dashboardStore.setConfig(updatedData)
}

const adjustForOffset = (timestamp, offset) => timestamp - (offset * 60)

watch(
    () => dashboardData.value,
    (value) => {
        if (!timeIsInit.value && value && Object.keys(value).length) {
            const now = new Date().getTime()
            time.value = value.chart[0] ? [value.chart[0].time * 1000, now] : [now, now]
            startTime.value = time.value
            timeIsInit.value = true
        }
    },
)

watch(
    () => sessionStore.offset,
    (newOffset) => {
        let fromToExist = currentConfig.value.from && currentConfig.value.to

        if (fromToExist && parseInt(newOffset) !== 0) {
            currentConfig.value.from = adjustForOffset(currentConfig.value.from, newOffset * -1)
            currentConfig.value.to = adjustForOffset(currentConfig.value.to, newOffset * -1)
        } else if (fromToExist) {
            currentConfig.value.from = adjustForOffset(currentConfig.value.from, new Date().getTimezoneOffset())
            currentConfig.value.to = adjustForOffset(currentConfig.value.to, new Date().getTimezoneOffset())
        }

        currentConfig.value.timeshift = convertOffsetToSeconds(sessionStore.offset !== undefined ? sessionStore.offset : new Date().getTimezoneOffset())
        dashboardStore.setConfig(currentConfig)
    }
)
</script>

<template>
    <div class="dashboard-screen container">
        <FiltersList
            :id="'dashboard'"
            :filters="filters"
            :title:="'Dashboard'"
            :button-text="'Filters'"
            @search="setConfig"
            @clear="setConfig"
        />
        <BaseSwitchBox v-model="time" :options="timesFilters" @update="setConfig"/>
        <div class="stats">
            <div class="item">
                <p class="typo-heading-small-semi-bold-gray">Conversion</p>
                <div class="content">
                    <div class="info-box">
                        <p v-if="Object.keys(dashboardData).length" class="info">
                            {{ (dashboardData.conversion * 100).toFixed(1) || 0 }}%
                        </p>
                        <BaseLoader v-else/>
                    </div>
                </div>
            </div>
            <div class="item">
                <p class="typo-heading-small-semi-bold-gray">Leads</p>
                <div class="content">
                    <div class="info-box">
                        <p class="info">
                            <span
                                v-if="Object.keys(dashboardData).length"
                                class="info-box">{{ dashboardData.leadsTotal }}
                            </span>
                            <BaseLoader v-else/>
                        </p>
                        <h6 v-if="Object.keys(dashboardData).length" class="typo-heading-h6 sub-title">Qualified</h6>
                    </div>
                    <div class="info-box">
                        <p class="info">
                            <span
                                v-if="Object.keys(dashboardData).length"
                                class="info-box">{{ dashboardData.failuresTotal }}
                            </span>
                            <BaseLoader v-else/>
                        </p>
                        <h6 v-if="Object.keys(dashboardData).length" class="typo-heading-h6 sub-title">Failed</h6>
                    </div>
                </div>
            </div>
            <div class="item">
                <p class="typo-heading-small-semi-bold-gray">FTD’s</p>
                <div class="content">
                    <div class="info-box">
                        <p class="info">
                            <span
                                v-if="Object.keys(dashboardData).length"
                                class="info-box">{{ dashboardData.ftdsTotal }}
                            </span>
                            <BaseLoader v-else/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="chart-container">
            <LineChart
                v-if="dashboardData.chart" :offset="sessionStore.offset" :data="dashboardData.chart"
                :period="period"/>
            <BaseLoader v-else/>
        </div>
        <div class="dashboard-table">
            <div class="table-pad">
                <BaseDashboardDatatable
                    v-if="Object.keys(dashboardData).length"
                    :cols="mainCols"
                    :data="dashboardData.brands.filter(el => el.leads)" :title="'Top Results'">
                    <template #title>
                        <div class="table-title">
                            <BaseImage
                                :path="'security.png'"
                                :width="18"
                                :height="22"
                            />
                            <h4 class="typo-heading-h4">Top Results</h4>
                        </div>
                    </template>
                    <template #row="{row}">
                        <th :class="{gray: !brands[row.id]}">{{ brands[row.id] || 'Deleted' }}</th>
                        <th>{{ row.leads }}</th>
                        <th>{{ row.ftds || 0 }}</th>
                        <th>{{ (row.ftds / row.leads * 100).toFixed(1) || 0 }}%</th>
                    </template>
                </BaseDashboardDatatable>
                <BaseLoader v-else/>
            </div>
            <div class="table-pad">
                <BaseDashboardDatatable
                    v-if="Object.keys(dashboardData).length"
                    :cols="brandFailuresCols"
                    :data="dashboardData.brands.filter(el => el.failures)"
                    :title="'Brand Failures'">
                    <template #title>
                        <div class="table-title">
                            <BaseImage
                                :width="18"
                                :height="22"
                                :path="'warning-red.png'"
                            />
                            <h4 class="red typo-heading-h4">Brand Failures:
                                {{ dashboardData.brands.filter(el => el.failures).length }}</h4>
                        </div>
                    </template>
                    <template #row="{row}">
                        <th :class="{gray: !brands[row.id]}">{{ brands[row.id] || 'Deleted' }}</th>
                        <th>{{ row.failures }}</th>
                    </template>
                </BaseDashboardDatatable>
                <BaseLoader v-else/>
            </div>
        </div>
    </div>
</template>

<style scoped>
.filters-list {
    margin-bottom: 20px;
}

.chart-container {
    border-radius: var(--general-border-radius);
    background: var(--dark-bg);
    margin-top: 8px;
    overflow: hidden;
}

.stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background: var(--gray-1000);
    margin: 20px 0 8px;
    border-radius: var(--general-border-radius);
}

.item {
    margin: 16px 0;
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item:nth-child(1) {
    padding: 0 5px 0 16px;
}

.item:nth-child(2) {
    padding: 0 10px;
    border-right: .5px solid var(--white-08);
    border-left: .5px solid var(--white-08);
}

.item:nth-child(3) {
    padding: 0 16px 0 5px;
}

.item:last-child {
    border: none;
}

.info-box {
    text-align: center;
}

.info {
    font-size: 15px;
}

.item .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 5px;
}

.dashboard-table {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 10px;
}

.table-title {
    display: flex;
    align-items: center;
    gap: 11px;
    margin-bottom: 9px;
    min-height: 35px;
}

.table-pad {
    background: var(--dark-bg);
    border-radius: var(--general-border-radius);
    padding: 24px;
}

th {
    text-align: left;
    font-size: 14px;
    color: var(--white-086);
    font-weight: 400;
}

.sub-title {
    margin-top: 4px;
    color: var(--white-086);
}

@media (max-width: 1000px) {
    .dashboard-table {
        grid-template-columns: 1fr;
    }

    .stats {
        margin: 0 0 8px;
    }

    .chart-container {
        height: 347px;
        overflow-x: auto;
    }
}

@media (max-width: 479px) {
    .stats {
        overflow-x: auto;
    }
}
</style>
