<script setup>
import { ref, computed } from 'vue'

import { convertStringToUuid } from '@/shared/lib/utils/uuid'
import { copyToClipboard } from '@/shared/lib/utils/copyToClipboard'

import { router } from '@/app/providers'
import config from '@/pages/partners/configs/partners.config'

const { tableId, cols, filters, tableActions, actionList, actions, searchByList } = config.config()

import { ModalModel } from '@/entities/Modal'
import { PartnersModel } from '@/entities/Partners'

import { PageTitle } from '@/shared/ui/Page'
import { BaseButton } from '@/shared/ui/Buttons'
import { DataTable } from '@/features/Datatable'
import { PaginationBox, FiltersList, TableActions } from '@/features/Datatable'
import { usePageTitle } from '@/shared/lib/use/usePageTitle'
import {BaseImage} from "@/shared/ui/Images";

const modalStore = ModalModel.useModalStore()
const partnersStore = PartnersModel.usePartnersStore()
const title = usePageTitle('Partners')

const apiDetail = computed(() => partnersStore.partnerDetails)

const registrationButton = ref('Get Registration Link')
const copied = ref(false)
const dataIsLoad = ref({})
const loadState = ref(false)

const edit = (row) => {
    partnersStore.setPartner(row)
    router.push({ name: 'partnerPage', params: { id: row.id.data } })
}

const update = (action, item, row) => {
    item.selectIsOpen = false
    item.selectType = 0
    switch (action) {
        case 'edit':
            edit(row)
            break
        default:
            break
    }
}

const create = () => {
    modalStore.setModal('createPartner')
}

const showApiDetails = (data) => {
    const id = data['id'].data
    if (loadState.value) {
        return
    }
    loadState.value = true
    dataIsLoad.value[id] = true

    partnersStore
        .getPartnerDetail(data['id'].data)
        .then(() => {
            modalStore.setModalData({
                username: apiDetail.value.username,
                url: apiDetail.value.url,
                documentation: apiDetail.value.documentation,
                token: apiDetail.value.token,
                id: id
            })
            modalStore.setModal('getPartnerDetails')
        })
        .finally(() => {
            dataIsLoad.value[id] = false
            loadState.value = false
        })
}

const generate = () => {
    const link = `${window.location.origin}/user/registration?parent=${convertStringToUuid(Date.now().toString())}` // It may be necessary to send a user ID in the future
    registrationButton.value = 'Copied'
    copied.value = true

    copyToClipboard(link)
    setTimeout(() => {
        registrationButton.value = 'Get registration link'
        copied.value = false
    }, 1000)
}
</script>

<template>
    <div class="partners-page container">
        <PageTitle :title="'Partners'" :icon="'page-icons/partners.svg'">
            <template #custom-action>
                <BaseButton :icon="'plus.svg'" :width="40" :height="40" :border-radius="'4px'" @click="create()" />
            </template>
        </PageTitle>
        <div class="registration-link">
            <BaseButton
                :design="'transparent'"
                :width="180"
                :height="35"
                :text="registrationButton"
                @click="generate"
            >
                <template #left-icon>
                    <BaseImage v-if="!copied" :path="'copy.svg'" :width="24" :height="24"/>
                </template>
            </BaseButton>
        </div>
        <DataTable
            :path="'partners/dt'"
            :table-id="tableId"
            :fields="cols"
            :option-list="tableActions"
            :action-list="actionList"
            :action-btn-title="'Apply'"
            :order="'numericId'"
            @open="edit"
            @action="update"
        >
            <template #top-left>
                <FiltersList :filters="filters" :search-list="searchByList" :button-text="'Search'" />
            </template>
            <template #center-right>
                <TableActions :actions="actions" :search-list="searchByList" />
            </template>
            <template #bottom-left>
                <PaginationBox />
            </template>
            <template #apiDetails="{ row }">
                <BaseButton
                    class="api-details"
                    :text="'Show API Details'"
                    :width="135"
                    :height="27"
                    :load="dataIsLoad[row['id'].data]"
                    @click.stop="showApiDetails(row)"
                />
            </template>
        </DataTable>
    </div>
</template>

<style scoped>
.right button {
    gap: 10px;
}

.actions,
.api-details.fill {
    padding: 6px 8px;
    border-radius: 4px;
    background: var(--purple);
    font-size: 14px;
    font-weight: 500;
    color: var(--white);
}

.registration-link {
    padding: 0 0 20px;
}

.registration-link button {
    justify-content: space-between;
    font-size: 14px;
    color: var(--white);
    font-weight: 400;
}
</style>
