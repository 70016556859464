export const getConnectors = (store) => store.connectors

export const getBrands = (store) => store.brands

export const getPartners = (store) => store.partners

export const getSources = (store) => store.sources

export const getUsers = (store) => store.users

export const getPermissions = (store) => store.permissions

export const getCampaigns = (store) => store.campaigns

export const getStatuses = (store) => store.statuses

export const getUser = (store) => store.user
