<script setup>
import {BaseMultiselect, BaseToggle} from '@/shared/ui/Inputs'
import {BaseTextarea, BaseSelectBox, BaseInput} from '@/shared/ui/Inputs'
import {BaseButton} from '@/shared/ui/Buttons'

import {capTypesList, timezonesList} from '@/shared/data'

import config from '@/pages/source/configs/source.config'

const {priorityList} = config.config()

import {computed, ref} from 'vue'

import {ModalModel} from '@/entities/Modal'
import {SessionModel} from '@/entities/Session'
import {useAppStore} from '@/app/providers'
import {SourcesModel} from '@/entities/Sources'
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {UpdateActivityHours} from '@/features/Sources'
import {BaseModal} from '@/shared/ui/Modal'
import {BaseBorderPanel} from '@/shared/ui/Panels'
import {convertToSchedule, isMobile} from '@/entities/Sources/model'
import {countriesList} from "@/shared/data/countriesList"
import {checkOnRequiredFields} from "@/shared/lib/utils/validation"

const modalStore = ModalModel.useModalStore()
const sessionStore = SessionModel.useSessionStore()
const appStore = useAppStore()
const store = SourcesModel.useSourcesStore()
const sourcesStore = SourcesModel.useSourcesStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

const brands = computed(() => SessionModel.getBrands(sessionStore))
const source = computed(() => sourcesStore.source)
const sources = computed(() => sourcesStore.sources)

const countriesSource = computed(() => {
    if (source.value.type === 1) {
        return countriesList()
    }
    return [...source.value.countries].sort()
})

const tips = computed(() => {
    if (source.value.type === 2) {
        return {}
    }

    return sources.value
        .filter(el => el.countries.length)
        .map((tip, key) => ({
            title: tip.name,
            key: key,
            value: tip.countries
        }))
})

const row = computed(() => store.row)

const priority = ref(row.value['priority'].data)
const scheduleTimezone = ref(row.value['scheduleTimezone'].data !== undefined ? row.value['scheduleTimezone'].data : '')
const brand = ref(row.value['brand'].data.id || '')
const countrySource = ref(row.value['source'].data.id || '')
const capType = ref(row.value['capType'].data || '')
const capDaily = ref(row.value['capDaily'].data || '')
const activityObject = ref({})
const schedule = ref(row.value['schedule'].data || null)
const description = ref(row.value['description'].data || '')
const status = ref(row.value['status'].data)
const countriesData = ref(row.value['countries'].data || [])
const selectedCountries = ref(countriesData.value)

const requireFields = ref({
    brand: {data: brand, error: false},
    country: {data: countriesData, error: false},
    capType: {data: capType, error: false},
    capDaily: {data: capDaily, error: false}
})

const close = () => {
    modalStore.closeModal()
}

const save = () => {
    if (!checkOnRequiredFields(requireFields.value)) {
        generalNotificationsStore.showError({
            message: 'Fill all required fields.'
        })
        return
    }

    appStore.update(
        {
            priority: priority.value,
            brandId: brand.value,
            sourceId: countrySource.value,
            capType: parseInt(capType.value),
            capDaily: capDaily.value,
            description: description.value,
            scheduleTimezone: scheduleTimezone.value,
            schedule: convertToSchedule(activityObject.value),
            countries: selectedCountries.value,
            status: status.value
        },
        row.value,
        'rows-table'
    ).then(() => {
        if (isMobile()) {
            modalStore.setModal('successful')
            modalStore.setModalData({
                option: 'change_settings',
            })
        } else {
            generalNotificationsStore.showSuccess({
                title: 'Changes Applied:',
                message: 'You’ve changed your settings successfully'
            })
        }
    })
    close()
}

const cancel = () => {
    close()
}

const setCountries = (data) => {
    selectedCountries.value = data['countries']
}
</script>

<template>
    <BaseModal>
        <template #title>
            <h4 class="typo-heading-h4">Edit row</h4>
        </template>
        <template #content>
            <div class="edit-row">
                <BaseBorderPanel>
                    <template #title>
                        <h5 class="typo-heading-h5">Basic</h5>
                    </template>
                    <template #content>
                        <div class="filters">
                            <BaseInput v-model="brands[brand]" :disabled="true" :label="'Brands*'"/>
                            <BaseMultiselect
                                :selected-values="selectedCountries"
                                :field="'countries'"
                                :options="Object.entries(countriesSource).map(([key, value]) => ({
                                        key: countriesList()[key] ? key : value,
                                        value: countriesList()[value] || value
                                    }))
                                "
                                :tips="tips"
                                :error="requireFields['country'].error"
                                :placeholder="'Select countries source'"
                                @change="setCountries"
                            >
                                <template #label>
                                    <label>Countries source*</label>
                                </template>
                                <template #tips-label>
                                    Sources:
                                </template>
                                <template #options-label>
                                    Countries:
                                </template>
                            </BaseMultiselect>
                            <BaseSelectBox
                                v-model="capType"
                                :label="'Cap type*'"
                                :error="requireFields['capType'].error"
                                :options="
                                    Object.entries(capTypesList).map(([key, value]) => ({
                                        value: key,
                                        title: value
                                    }))
                                "
                            >
                                <template #placeholder>
                                    {{ capTypesList[capType] }}
                                </template>
                            </BaseSelectBox>
                            <BaseInput
                                v-model="capDaily"
                                :error="requireFields['capDaily'].error"
                                :padding="{ left: '10px' }"
                                :label="'Daily cap value*'"
                                :type="'number'"
                                :placeholder="'Enter daily cap value'"
                            />
                            <BaseSelectBox
                                v-model="priority"
                                :label="'Priority'"
                                :options="
                                    Object.entries(priorityList).map(([key, value]) => ({
                                        value: key,
                                        title: value
                                    }))
                                "
                            >
                                <template #placeholder>
                                    {{ priorityList[priority] || 'Select priority' }}
                                </template>
                            </BaseSelectBox>
                        </div>
                    </template>
                </BaseBorderPanel>

                <BaseBorderPanel class="time-zone">
                    <template #title>
                        <h5 class="typo-heading-h5">Time zone</h5>
                    </template>
                    <template #content>
                        <div class="item">
                            <BaseSelectBox
                                v-model="scheduleTimezone"
                                :is-sorted="false"
                                :options="
                                    Object.entries(timezonesList).map(([key, value]) => ({
                                        value: key,
                                        title: value
                                    }))
                                "
                                :width="110"
                                :label="'Choose time zone'"
                            >
                                <template #placeholder>
                                    {{ timezonesList[scheduleTimezone] || 'Select time zone' }}
                                </template>
                            </BaseSelectBox>
                        </div>
                    </template>
                </BaseBorderPanel>

                <BaseBorderPanel class="activity">
                    <template #title>
                        <h5 class="typo-heading-h5">Activity hours</h5>
                    </template>
                    <template #content>
                        <UpdateActivityHours v-model="activityObject" :schedule="schedule"/>
                    </template>
                </BaseBorderPanel>

                <BaseBorderPanel class="info">
                    <template #label>
                        <h5 class="typo-heading-h5">Information</h5>
                    </template>
                    <template #content>
                        <BaseTextarea v-model="description" :placeholder="'Enter the description'" :height="91" :label="'Description'"/>
                    </template>
                </BaseBorderPanel>

                <BaseBorderPanel :border="false">
                    <template #content>
                        <div class="traffic">
                            <h5 class="typo-heading-h5">Enable traffic</h5>
                            <div class="switch-box">
                                <BaseToggle :value="status" @change="(value) => (status = value)"/>
                                {{ status ? 'On' : 'Off' }}
                            </div>
                        </div>
                    </template>
                </BaseBorderPanel>
            </div>
        </template>
        <template #action-buttons>
            <BaseButton class="cancel" :text="'Cancel'" :design="'outline'" @click="cancel()"/>
            <BaseButton class="save" :text="'Save'" @click="save()"/>
        </template>
    </BaseModal>
</template>

<style scoped>
.modal-box {
    padding: 130px 24px 0;
}

.filters {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 260px);
    gap: 20px;
    place-items: center;
}

.traffic {
    display: flex;
    align-items: center;
    gap: 8px;
}

.time-zone {
    margin-bottom: 30px;
}

.time-zone .item {
    display: flex;
    align-items: center;
    gap: 30px;
}

.item {
    width: 100%;
}

.switch {
    display: flex;
    align-items: center;
    gap: 10px;
}

.switch-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input-box,
.select {
    width: 100%;
}

.activity .input-box {
    display: inline-grid;
    grid-template-rows: max-content 1fr;
    grid-template-columns: repeat(2, max-content);
    place-items: center;
    column-gap: 10px;
}

.activity .input-container {
    background-color: var(--select-bg);
    border-radius: var(--general-border-radius);
    display: grid;
    grid-template-columns: 110px max-content;
    place-items: center stretch;
    height: fit-content;
}

.activity input {
    outline: none;
    color: var(--primary-white);
    background: transparent;
    line-height: 1;
    font-weight: var(--general-font-weight);
    width: 100%;
    text-overflow: ellipsis;
    height: 100%;
    padding: 5px 10px;
}

.activity input::placeholder {
    font-weight: var(--general-font-weight);
    color: var(--primary-gray);
}

.activity .approve {
    border-radius: 0 var(--general-border-radius) var(--general-border-radius) 0;
}

@media (max-width: 1000px) {
    .filters {
        grid-template-columns: 1fr;
    }

    .activity-item {
        grid-template-columns: 30px max-content 1fr;
    }

    .time-container {
        width: 100%;
    }

    .time-zone .item {
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
    }

    .time-zone .item .select {
        width: 100% !important;
    }

    .cancel {
        width: 100px !important;
    }

    .save {
        width: 100px !important;
    }
}
</style>
