<script setup>
import {defineProps, defineEmits, ref} from "vue"
import {BaseInput} from "@/shared/ui/Inputs"
import {BaseButton} from "@/shared/ui/Buttons"
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'

const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

const emits = defineEmits(['save'])

const props = defineProps({
    params: {
        type: Array,
        default: () => []
    }
})

const localParams = ref(JSON.parse(JSON.stringify(props.params)))

const save = (item) => {
    if (!item.key || !item.value) {
        if (Object.keys(localParams.value).length === 1) {
            item.state = false
        }
        return
    }

    const isDuplicate = Object.values(localParams.value).some((val) => {
        return val.state === true && val.key === item.key
    })

    if (isDuplicate) {
        generalNotificationsStore.showError({
            message: 'Key already added'
        })
        return
    }

    item.state = true

    const savedObject = Object.fromEntries(
        Object.entries(localParams.value).filter(([key, val]) => val.state === true)
    )

    emits('save', JSON.parse(JSON.stringify(savedObject)))
}

const clear = (item, index) => {
    let existingItem

    if (Array.isArray(props.params)) {
        existingItem = props.params.find((param, i) => param.key === item.key && i === index);
    }

    if (existingItem) {
        item.value = existingItem.value
        item.state = existingItem.state
    } else {
        if (Array.isArray(localParams.value)) {
            if (index >= 0 && index < localParams.value.length) {
                const localItem = localParams.value[index]

                if (localItem.key === item.key) {
                    localParams.value.splice(index, 1)
                }
            }
        }
    }
}

const edit = (item, index) => {
    localParams.value[index].state = false
}

const remove = (index) => {
    if (Array.isArray(localParams.value)) {
        if (localParams.value.length === 1) {
            localParams.value[0] = { key: '', value: '', state: false }
        } else if (index >= 0 && index < localParams.value.length && localParams.value[index].state === true) {
            localParams.value.splice(index, 1)
        }
    } else {
        const keys = Object.keys(localParams.value);
        if (keys.length === 1) {
            localParams.value[keys[0]] = { key: '', value: '', state: false }
        } else if (localParams.value[index]?.state === true) {
            delete localParams.value[index]
        }
    }

    emits('save', JSON.parse(JSON.stringify(
        Object.values(localParams.value).filter(val => val.state === true).length === 0
            ? [{ key: "", value: "", state: false }]
            : Object.values(localParams.value).filter(val => val.state === true)
    )))
}

const addNewParam = () => {
    if (Array.isArray(localParams.value)) {
        localParams.value.push({
            key: '',
            value: '',
            state: false
        })
    } else {
        const keys = Object.keys(localParams.value).map(Number).filter(k => !isNaN(k))
        const newKey = keys.length > 0 ? Math.max(...keys) + 1 : 1

        localParams.value[newKey] = {
            key: '',
            value: '',
            state: false
        }
    }
}
</script>

<template>
    <div class="params-container">
        <div
            v-for="(param, index) in localParams"
            :key="index"
            class="params-list"
        >
            <div class="param-item">
                <BaseInput
                    v-model="param.key"
                    :disabled="param.state"
                    :placeholder="'Enter Key...'"
                    :label="'Key'"
                />
            </div>
            <div class="param-item">
                <BaseInput
                    v-model="param.value"
                    :disabled="param.state"
                    :placeholder="'Enter Value...'"
                    :label="'Value'"
                />
            </div>
            <div v-if="param.state" class="saved actions">
                <BaseButton
                    :icon="'edit.svg'"
                    :design="'outline'"
                    @click.stop="edit(param, index)"
                />
                <BaseButton
                    :icon="'delete.svg'"
                    :design="'outline'"
                    :icon-height="16"
                    :icon-width="14"
                    @click.stop="remove(index)"
                />
            </div>
            <div v-else class="actions">
                <BaseButton
                    :text="'Cancel'"
                    :design="'outline'"
                    @click.stop="clear(param, index)"
                />
                <BaseButton
                    :text="'Save'"
                    @click.stop="save(param)"
                />
            </div>
        </div>
        <BaseButton
            :text="'Add new'"
            :width="160"
            :height="36"
            @click.stop="addNewParam"
        />
    </div>
</template>

<style scoped>
.params-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 25px;
    align-items: flex-end;
}

.params-list:last-child {
    margin-bottom: 0;
}

.param-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1 1 25%;
}

.actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.saved {
    display: grid;
    grid-template-columns: repeat(2, 40px);
    gap: 20px;
}

.params-list button {
    width: 100% !important;
}

@media (max-width: 768px) {
    .params-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .params-list {
        grid-template-columns: 1fr;
    }
}
</style>
