<script setup>
import {computed, ref} from 'vue'
import {BaseButton} from '@/shared/ui/Buttons'
import {BaseTextarea, BaseSelectBox} from '@/shared/ui/Inputs'

import {ModalModel} from '@/entities/Modal'
import {SessionModel} from '@/entities/Session'
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'

import {useAppStore} from '@/app/providers'
import {BaseModal} from '@/shared/ui/Modal'
import {checkOnRequiredFields} from "@/shared/lib/utils/validation";
import {PartnersModel} from "@/entities/Partners"

const modalStore = ModalModel.useModalStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const sessionStore = SessionModel.useSessionStore()
const appStore = useAppStore()
const store = PartnersModel.usePartnersStore()

const partnerId = computed(() => PartnersModel.getPartner(store).id.data)

const brands = computed(() => SessionModel.getBrands(sessionStore))
const requestIsPending = computed(() => appStore.requestIsPending)

const note = ref('')
const brandId = ref('')

const requireFields = ref({
    brandId: {data: brandId, error: false},
})

const create = () => {
    if (!checkOnRequiredFields(requireFields.value)) {
        generalNotificationsStore.showError({
            message: 'Fill all required fields.'
        })
        return
    }

    appStore.create(
        {
            partnerId: partnerId.value,
            status: 1,
            note: note.value
        },
        `brand/${brandId.value}/affiliates`
    )
}

const cancel = () => {
    close()
}

const close = () => {
    modalStore.closeModal()
}
</script>

<template>
    <BaseModal :title="'Create new brand'">
        <template #content>
            <div class="create-affiliate">
                <BaseSelectBox
                    v-model="brandId"
                    :options="
                        Object.entries(brands).map(([key, value]) => ({
                            value: key,
                            title: value
                        }))
                    "
                    :error="requireFields['brandId'].error"
                    :label="'Owner of the partner*'"
                    :placeholder="'Select owner'"
                />
                <BaseTextarea
                    v-model="note"
                    :placeholder="'Enter note'"
                    :height="91"
                    :label="'Note'"
                />
            </div>
        </template>
        <template #action-buttons>
            <BaseButton :text="'Cancel'" :width="100" :design="'outline'" @click="cancel()"/>
            <BaseButton :text="'Create'" :width="100" :load="requestIsPending" @click="create()"/>
        </template>
    </BaseModal>
</template>

<style scoped>
.textarea-box {
    margin-top: 16px;
}

.select {
    width: 100%;
}
</style>
