export const minutesToTime = ({ from, to }) => {
    const formatTime = (time) => {
        const hours = Math.floor(time / 60)
        const minutes = time % 60
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    }

    return `${formatTime(from)} - ${formatTime(to)}`
}

export const timeToMinutes = (time) => {
    const [start, end] = time.split(' - ')
    const [startHour, startMinute] = start.split(':').map(Number)
    const [endHour, endMinute] = end.split(':').map(Number)
    const from = startHour * 60 + startMinute
    const to = endHour * 60 + endMinute
    return { from, to }
}

export const isValidTime = (time) => {
    const [hours, minutes] = time.split(':').map(Number)
    return hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60
}

export const isValidTimeRange = (timeRange) => {
    const timeRangeRegex = /^\d{2}:\d{2}\s*-\s*\d{2}:\d{2}$/
    if (!timeRangeRegex.test(timeRange)) {
        return false
    }

    const [startTime, endTime] = timeRange.split(' - ')

    if (startTime === endTime) {
        return false
    }

    if (!isValidTime(startTime) || !isValidTime(endTime)) {
        return false
    }

    const [startHours, startMinutes] = startTime.split(':').map(Number)
    const [endHours, endMinutes] = endTime.split(':').map(Number)

    return !(endHours < startHours || (endHours === startHours && endMinutes < startMinutes))
}

export const convertToSchedule = (activity) => {
    return Object.values(activity).map((activity) => {
        if (activity.state) {
            return timeToMinutes(activity.time)
        } else {
            return {}
        }
    })
}

export const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
