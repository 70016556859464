<script setup>
import { computed, ref } from 'vue'

import {  statusList } from '@/shared/data'

import { SessionModel } from '@/entities/Session'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'

import { BaseInput, BaseSelectBox } from '@/shared/ui/Inputs'
import { BaseButton } from '@/shared/ui/Buttons'
import { UsersModel } from '@/entities/Users'
import { UserModel } from '@/entities/User'
import { BaseBorderPanel } from '@/shared/ui/Panels'
import { BasePermissionsList } from '@/entities/Users/ui/PermisionsList'
import {useAppStore} from "@/app/providers"
import {countriesList} from "@/shared/data/countriesList"
import {useRouter} from "vue-router"
import {checkOnRequiredFields} from "@/shared/lib/utils/validation"

const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const sessionStore = SessionModel.useSessionStore()
const usersStore = UsersModel.useUsersStore()
const userStore = UserModel.useUserStore()
const appStore = useAppStore()
const router = useRouter()

const users = computed(() => SessionModel.getUsers(sessionStore))
const user = computed(() => usersStore.user)
const authUser = computed(() => userStore.user)

const username = ref(user.value['username'].data || '')
const firstname = ref(user.value['firstname'].data || '')
const lastname = ref(user.value['lastname'].data || '')
const skype = ref(user.value['skype'].data || '')
const email = ref(user.value['email'].data || '')
const domain = ref(user.value['domain'].data || '')
const telegram = ref(user.value['telegram'].data || '')
const country = ref(user.value['country'].data || '')
const password = ref('')
const confirmPassword = ref('')
const status = ref(user.value['status'].data || 0)
const permissions = ref(user.value['permissions'].data || '')
const companyName = ref(user.value['companyName'].data || '')
const companyRegNumber = ref(user.value['companyRegNumber'].data || '')
const companyAddress = ref(user.value['companyAddress'].data || '')
const companyVat = ref(user.value['companyVat'].data || '')
const userIdCreated = ref(user.value['userIdCreated'].data || '')

const dataIsSaving = ref(false)

const requireFields = ref({
    lastname: { data: lastname, error: false },
    firstname: { data: firstname, error: false },
    username: { data: username, error: false },
})

const save = () => {
    if (!checkOnRequiredFields(requireFields.value)) {
        generalNotificationsStore.showError({
            message: 'Fill all required fields.'
        })
        return
    }

    dataIsSaving.value = true
    let data = {}

    permissions.value = permissions.value.filter(permission =>
        permission in sessionStore.permissions
    )

    data = {
        status: status.value,
        firstname: firstname.value,
        lastname: lastname.value,
        skype: skype.value,
        email: email.value,
        username: username.value,
        country: country.value,
        permissions: permissions.value,
        telegram: telegram.value,
        domain: domain.value,
        companyName: companyName.value,
        companyAddress: companyAddress.value,
        companyVat: companyVat.value,
        companyRegNumber: companyRegNumber.value,
        userIdCreated: userIdCreated.value
    }

    if (password.value) {
        data.password = password.value
    }

    usersStore
        .update(user.value['id'].data, data)
        .then(() => {
            generalNotificationsStore.showSuccess({
                title: 'Changes Applied:',
                message: 'You’ve changed your settings successfully'
            })
            appStore.getList('users')
        })
        .finally(() => {
            dataIsSaving.value = false
        })
}

const cancel = () => {
    router.push({ name: 'usersPage' })
}
</script>

<template>
    <div class="general-tab">
        <BaseBorderPanel :border="false">
            <template #title>
                <h2 class="main-title mob-tablet-button">General information</h2>
            </template>
            <template #label>
                <p class="mob-tablet-h8">Fill all forms</p>
            </template>
            <template #content>
                <div class="option-list">
                    <BaseInput v-model="username" :error="requireFields['username'].error" :placeholder="'Enter Username'" :label="'Username*'" />
                    <BaseInput v-model="firstname"  :error="requireFields['firstname'].error" :placeholder="'Enter First name'" :label="'First Name*'" />
                    <BaseInput v-model="lastname"  :error="requireFields['lastname'].error" :placeholder="'Enter Last name'" :label="'Last Name*'" />
                    <BaseInput v-model="email" :placeholder="'Email address'" :disabled="true" :label="'Email'" />
                    <BaseInput v-model="telegram" :placeholder="'Enter Telegram'" :label="'Telegram'" />
                    <BaseInput v-model="skype" :placeholder="'Enter Skype'" :label="'Skype'" />
                    <BaseInput v-model="domain" :placeholder="'Enter Domain'" :label="'Domain'" />
                    <!--                <BaseInput v-model="upline" :placeholder="'Enter Upline'"/>-->
                    <BaseInput v-model="companyName" :placeholder="'Enter your Company Name'" :label="'Company Name'" />
                    <BaseInput
                        v-model="companyAddress"
                        :placeholder="'Enter your Company Address'"
                        :label="'Company Address'"
                    />
                    <BaseInput
                        v-model="companyRegNumber"
                        :placeholder="'Enter your Req Number'"
                        :label="'Registration Number'"
                    />
                    <BaseInput v-model="companyVat" :placeholder="'Enter your VAT ID'" :label="'VAT ID'" />
                    <BaseSelectBox
                        v-model="country"
                        :label="'Country'"
                        :options="
                            Object.entries(countriesList()).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                    >
                        <template #placeholder>
                            {{ country ? countriesList()[country] : 'Select Country' }}
                        </template>
                    </BaseSelectBox>
                </div>
            </template>
        </BaseBorderPanel>
        <BaseBorderPanel v-if="userIdCreated" :border="false">
            <template #title>
                <h2 class="mob-tablet-button">Owner</h2>
            </template>
            <template #label>
                <p class="mob-tablet-h8">Select owner</p>
            </template>
            <template #content>
                <div class="option-list">
                    <BaseSelectBox
                        v-model="userIdCreated"
                        :disable="authUser && authUser['permissions'].indexOf('admin') < 0"
                        :options="
                            Object.entries(users).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                        :placeholder="users[userIdCreated] || 'Select Owner'"
                    />
                </div>
            </template>
        </BaseBorderPanel>
        <BaseBorderPanel :border="false">
            <template #title>
                <h2 class="mob-tablet-button">Permissions for user</h2>
            </template>
            <template #label>
                <p class="mob-tablet-h8">Select what pages user can use.</p>
            </template>
            <template #content>
                <BasePermissionsList v-model="permissions" />
            </template>
        </BaseBorderPanel>
        <BaseBorderPanel :border="false">
            <template #title>
                <h2 class="mob-tablet-button">Status</h2>
            </template>
            <template #label>
                <p class="mob-tablet-h8">Select status of the partner</p>
            </template>
            <template #content>
                <div class="option-list">
                    <BaseSelectBox
                        v-model="status"
                        :placeholder="statusList[status] || 'Select Status'"
                        :options="
                            Object.entries(statusList).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                    />
                </div>
            </template>
        </BaseBorderPanel>
        <BaseBorderPanel :border="false">
            <template #title>
                <h2 class="mob-tablet-button">Password</h2>
            </template>
            <template #label>
                <p class="mob-tablet-h8">
                    Enter password and click on “SAVE” button in the bottom of the page
                </p>
            </template>
            <template #content>
                <div class="option-list">
                    <BaseInput v-model="password" :type="'password'" :placeholder="'Enter Password'" />
                    <BaseInput v-model="confirmPassword" :type="'password'" :placeholder="'Confirm Password'" />
                </div>
            </template>
        </BaseBorderPanel>
        <div class="actions">
            <BaseButton :width="164" :text="'Cancel'" :height="40" :design="'outline'" @click="cancel()" />
            <BaseButton :width="164" :text="'Save'" :height="40" :load="dataIsSaving" @click="save()" />
        </div>
    </div>
</template>

<style scoped>
p {
    margin-top: 10px;
}

.option-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-top: 20px;
}

.actions {
    display: flex;
    align-items: center;
    gap: 24px;
}

.main-title {
    margin-top: 16px;
}

@media (max-width: 1024px) {
    .option-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .option-list {
        grid-template-columns: repeat(1, 1fr);
    }

    .actions {
        justify-content: space-between;
    }
}

@media (max-width: 360px) {
    .actions button {
        width: 124px !important;
    }
}
</style>
