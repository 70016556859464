<script setup>
import {computed, ref} from 'vue'

import {BaseInput, BaseSelectBox, BaseTextarea, BaseToggle} from '@/shared/ui/Inputs'
import {BaseButton} from '@/shared/ui/Buttons'

import {BrandsModel} from '@/entities/Brands'
import {useAppStore} from '@/app/providers'
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {SessionModel} from '@/entities/Session'
import {BaseBorderPanel} from '@/shared/ui/Panels'
import {checkOnRequiredFields, isValidDomain} from "@/shared/lib/utils/validation"
import {useModalStore} from "@/entities/Modal/model"
import {isMobile} from "@/entities/Sources/model"
import ParamsList from "@/features/Brands/ParamsList/ui/ParamsList.vue"
import {BaseImage} from "@/shared/ui/Images"
import {useRouter} from "vue-router";

const router = useRouter()

const store = BrandsModel.useBrandsStore()
const appStore = useAppStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const sessionStore = SessionModel.useSessionStore()
const modalStore = useModalStore()

const brand = computed(() => BrandsModel.getBrand(store))
const connectors = computed(() => SessionModel.getConnectors(sessionStore))
const users = computed(() => SessionModel.getUsers(sessionStore))

const convertedParams = () => {
    let params = brand.value['apiUrlParams']?.data
    if (!params) {
        return [{key: '', value: '', state: false}]
    }

    return Object.entries(params).map(([key, value]) => ({
        key: key,
        value: value,
        state: true
    }))
}

const note = ref(brand.value.note.data || '')
const emailValidation = ref(brand.value?.emailValidation.data || 0)
const privacy = ref(brand.value?.privacy.data || 0)
const name = ref(brand.value?.name.data || 0)
const apiConnector = ref(brand.value['apiConnector']?.data || '')
const apiToken = ref(brand.value['apiToken']?.data || '')
const apiUrl = ref(brand.value['apiUrl']?.data || '')
const userIdCreated = ref(brand.value['userIdCreated']?.data || '')
const apiUrlParam1 = ref(brand.value['apiUrlParam1']?.data || '')
const isShowParamsList = ref(false)
const paramsList = ref(convertedParams() || [
    {
        key: '',
        value: '',
        state: false
    }
])

const requireFields = ref({
    name: {data: name, error: false},
    apiToken: {data: apiToken, error: false},
    apiUrl: {data: apiUrl, error: false},
})

const saving = ref(false)

const save = () => {
    saving.value = true
    if (!checkOnRequiredFields(requireFields.value)) {
        generalNotificationsStore.showError({
            message: 'Fill all required fields.'
        })
        saving.value = false
        return
    }

    if (!isValidDomain(apiUrl.value)) {
        generalNotificationsStore.showError({
            message: 'Not valid Api URl'
        })
        saving.value = false
        return
    }

    if (!name.value) {
        generalNotificationsStore.showError({
            message: 'Incorrect Brand Name'
        })
        saving.value = false
        return
    }

    let tempParamsList = {}

    for (const param in paramsList.value) {
        tempParamsList[paramsList.value[param].key] = paramsList.value[param].value
    }

    appStore
        .update(
            {
                note: note.value,
                privacy: privacy.value,
                emailValidation: emailValidation.value,
                apiConnector: apiConnector.value,
                apiToken: apiToken.value,
                apiUrl: apiUrl.value,
                userIdCreated: userIdCreated.value,
                name: name.value,
                apiUrlParam1: apiUrlParam1.value,
                apiUrlParams: tempParamsList
            },
            brand.value,
            'brands-table'
        )
        .then(() => {
            if (isMobile()) {
                modalStore.setModal('successful')
                modalStore.setModalData({
                    option: 'change_settings',
                })
            } else {
                generalNotificationsStore.showSuccess({
                    title: 'Changes Applied:',
                    message: 'You’ve changed your settings successfully'
                })
            }
            saving.value = false
            appStore.getList('brands')
        })
        .catch(() => {
            saving.value = false
        })
}

const changePrivacy = (option) => {
    privacy.value = option
}

const close = () => {
    router.push({name: "brandsPage"})
}

const saveParamsList = (params) => {
    paramsList.value = params
}

const showParamsList = () => {
    isShowParamsList.value = !isShowParamsList.value
}
</script>

<template>
    <div class="settings-tab">
        <BaseBorderPanel>
            <template #title>
                <h2 class="mob-tablet-h7">Add Parameters for API Campaign</h2>
            </template>
            <template #content>
                <div class="row api">
                    <BaseInput
                        v-model="name"
                        :placeholder="'Enter Brand Name'"
                        :label="'*Brand Parameter'"
                        :error="requireFields['name'].error"
                    />
                    <BaseInput
                        v-model="apiToken"
                        :placeholder="'Enter API Token'"
                        :label="'*API Token'"
                        :error="requireFields['apiToken'].error"
                    />
                    <BaseInput
                        v-model="apiUrl"
                        :placeholder="'Enter API Url'"
                        :error="requireFields['apiUrl'].error"
                        :label="'*Brand API Urls'"
                    />
                    <BaseInput
                        v-model="apiUrlParam1"
                        :placeholder="'Enter Api Url Param1'"
                    >
                        <template #label>
                            <label>Api Url Param1</label>
                        </template>
                    </BaseInput>
                    <BaseSelectBox
                        v-model="apiConnector"
                        :options="
                            Object.entries(connectors).map(([key, value]) => ({
                                value: value,
                                title: value
                            }))
                        "
                        :placeholder="apiConnector || 'Select Connector'"
                    >
                        <template #label>
                            <label>Connector</label>
                        </template>
                    </BaseSelectBox>
                    <BaseSelectBox
                        v-model="userIdCreated"
                        :options="
                            Object.entries(users).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                        :placeholder="users[userIdCreated] || 'Select Owner'"
                        :label="'Owner'"
                    />
                </div>
            </template>
        </BaseBorderPanel>

        <BaseBorderPanel>
            <template #title>
                <h5 class="pointer mob-tablet-h7" :class="{open: isShowParamsList}" @click="showParamsList()">
                    Additional parameters
                    <BaseImage :height="5" :width="10" :path="'arrow.svg'"/>
                </h5>
            </template>
            <template v-if="isShowParamsList" #content>
                <ParamsList
                    :params="paramsList"
                    @save="saveParamsList"
                />
            </template>
        </BaseBorderPanel>

        <BaseBorderPanel>
            <template #title>
                <h2 class="mob-tablet-h7">Privacy</h2>
            </template>
            <template #content>
                <div class="row info-box">
                    <div class="info">
                        <p class="typo-mob-tablet-h9">What is the campaign privacy?</p>
                        <p class="typo-mob-tablet-h9">Public campaigns will include all affiliates automatically.</p>
                        <p class="typo-mob-tablet-h9">Private campaigns will only include manually added affiliates</p>
                    </div>
                    <div class="buttons">
                        <BaseButton
                            :text="'Public'"
                            :width="138"
                            :height="40"
                            :design="`${privacy ? 'outline' : 'fill'}`"
                            @click="changePrivacy(0)"
                        />
                        <BaseButton
                            :text="'Private'"
                            :width="138"
                            :height="40"
                            :design="`${!privacy ? 'outline' : 'fill'}`"
                            @click="changePrivacy(1)"
                        />
                    </div>
                </div>
            </template>
        </BaseBorderPanel>

        <BaseBorderPanel>
            <template #title>
                <h2 class="mob-tablet-h7">Check Email Validation</h2>
            </template>
            <template #content>
                <span class="typo-mob-tablet-h9">
                    When active, invalid emails result in lead failure and appear as unassigned.
                </span>
                <div class="switch-content">
                    <span class="typo-mob-tablet-h9">Check Email Validation</span>
                    <BaseToggle :value="emailValidation" @change="(value) => (emailValidation = value)">
                        <template #state>
                            {{ emailValidation ? 'On' : 'Off' }}
                        </template>
                    </BaseToggle>
                </div>
            </template>
        </BaseBorderPanel>
        <BaseBorderPanel :border="false">
            <template #content>
                <div class="row">
                    <BaseTextarea
                        v-model="note"
                        :height="90"
                        :label="'Note'"
                        :placeholder="'Enter the description'"
                    />
                </div>
            </template>
        </BaseBorderPanel>
        <div class="row">
            <div class="save">
                <BaseButton :width="100" :design="'outline'" :height="40" :text="'Cancel'" @click="close()"/>
                <BaseButton :width="66" :height="40" :text="'Save'" :load="saving" @click="save()"/>
            </div>
        </div>
    </div>
</template>

<style scoped>
p {
    margin-bottom: 10px;
}

p:last-child {
    margin-bottom: 0;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 24px;
}

.item .input-box {
    margin-bottom: 20px;
}

.item .input-box:last-child {
    margin-bottom: 0;
}

.switch-content {
    margin: 20px 0 0;
    display: flex;
    align-items: center;
    gap: 20px;
}

.row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.save {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.save button {
    width: 100% !important;
}

.switch-box {
    gap: 6px;
}

.input-box {
    display: flex;
    flex-direction: column;
}

.open img {
    transform: rotate(180deg);
}

@media (max-width: 1024px) {
    .row {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .row {
        grid-template-columns: 1fr;
    }

    .buttons {
        justify-content: space-between;
    }

    .buttons button {
        width: 100%;
    }
}
</style>
