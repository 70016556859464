<script setup>
import { ref, computed } from 'vue'

import { ModalModel } from '@/entities/Modal'
import { useAppStore } from '@/app/providers'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'

import { BaseButton } from '@/shared/ui/Buttons'
import { BaseWarningBox } from '@/shared/ui/Warnings'
import { BaseInput } from '@/shared/ui/Inputs'
import {checkOnRequiredFields, isValidEmail} from '@/shared/lib/utils/validation'
import { BaseModal } from '@/shared/ui/Modal'
import { BaseBorderPanel } from '@/shared/ui/Panels'
import { BasePermissionsList } from '@/entities/Users/ui/PermisionsList'

const modalStore = ModalModel.useModalStore()
const appStore = useAppStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

const requestIsPending = computed(() => appStore.requestIsPending)

const password = ref('')
const confirmPassword = ref('')
const email = ref('')
const firstname = ref('')
const lastname = ref('')
const phone = ref('')
const telegram = ref('')
const note = ref('')
const username = ref('')
const upline = ref('')
const domain = ref('')

const warningMessage = ref('')
const permissions = ref([])

const close = () => {
    modalStore.closeModal()
}

const requireFields = ref({
    username: { data: username, error: false },
    lastname: { data: lastname, error: false },
    firstname: { data: firstname, error: false },
    password: { data: password, error: false },
    confirmPassword: { data: confirmPassword, error: false },
    email: { data: confirmPassword, error: false }
})

const create = () => {
    if (!checkOnRequiredFields(requireFields.value)) {
        generalNotificationsStore.showError({
            message: 'Fill all required fields.'
        })
        return
    }

    if (!isValidEmail(email.value)) {
        generalNotificationsStore.showError({
            message: 'Invalid Email'
        })
        requireFields.value['email'].error = true
        return
    }

    if (password.value !== confirmPassword.value) {
        generalNotificationsStore.showError({
            message: "Passwords don't match"
        })
        return
    }

    appStore.create(
        {
            email: email.value,
            firstname: firstname.value,
            lastname: lastname.value,
            phone: phone.value,
            telegram: telegram.value,
            username: username.value,
            password: password.value,
            permissions: permissions.value,
            note: note.value,
            domain: domain.value,
            upline: upline.value,
            status: 1
        },
        'users'
    )
        .then(() => {
            appStore.getList('users')
        })
}
</script>

<template>
    <BaseModal :title="'Create New User'">
        <template #content>
            <div class="create-user">
                <BaseBorderPanel>
                    <template #title>
                        <h3 class="typo-heading-h3">General Information</h3>
                    </template>
                    <template #label>
                        <span class="typo-heading-small-semi-bold-gray"
                            >All fields with * symbol are required to fill</span
                        >
                    </template>
                    <template #content>
                        <div class="items">
                            <BaseInput
                                v-model="username"
                                :error="requireFields['username'].error"
                                :placeholder="'Enter Username'"
                            >
                                <template #label>
                                    <label>Username*</label>
                                </template>
                            </BaseInput>
                            <BaseInput
                                v-model="firstname"
                                :error="requireFields['firstname'].error"
                                :placeholder="'Enter First Name'"
                            >
                                <template #label>
                                    <label>First Name*</label>
                                </template>
                            </BaseInput>
                            <BaseInput
                                v-model="lastname"
                                :error="requireFields['lastname'].error"
                                :placeholder="'Enter Last Name'"
                            >
                                <template #label>
                                    <label>Last Name*</label>
                                </template>
                            </BaseInput>
                        </div>
                    </template>
                </BaseBorderPanel>
                <BaseBorderPanel>
                    <template #title>
                        <h3 class="typo-heading-h3">Additional Information</h3>
                    </template>
                    <template #content>
                        <div class="items">
                            <BaseInput v-model="email" :error="requireFields['email'].error" :placeholder="'Enter Email'">
                                <template #label>
                                    <label>Email*</label>
                                </template>
                            </BaseInput>
                            <BaseInput v-model="phone" :type="'number'" :placeholder="'Enter Phone number'">
                                <template #label>
                                    <label>Phone Number </label>
                                </template>
                            </BaseInput>
                            <BaseInput v-model="telegram" :placeholder="'Enter Telegram'">
                                <template #label>
                                    <label>Telegram</label>
                                </template>
                            </BaseInput>
                            <BaseInput v-model="domain" :placeholder="'Enter Domain'">
                                <template #label>
                                    <label>Domain</label>
                                </template>
                            </BaseInput>
                            <BaseInput v-model="upline" :placeholder="'Enter Upline'">
                                <template #label>
                                    <label>Upline</label>
                                </template>
                            </BaseInput>
                            <BaseInput v-model="note" :limit="50" :placeholder="'Enter Note'">
                                <template #label>
                                    <label>Note </label>
                                </template>
                            </BaseInput>
                        </div>
                    </template>
                </BaseBorderPanel>
                <BaseBorderPanel>
                    <template #title>
                        <h3 class="typo-heading-h3">Password</h3>
                    </template>
                    <template #label>
                        <p class="typo-heading-small-semi-bold-gray">Please enter password</p>
                    </template>
                    <template #content>
                        <div class="items">
                            <BaseInput
                                v-model="password"
                                :type="'password'"
                                :error="requireFields['password'].error"
                                :placeholder="'Enter Password'"
                            >
                                <template #label>
                                    <label>Password </label>
                                </template>
                            </BaseInput>
                            <BaseInput
                                v-model="confirmPassword"
                                :type="'password'"
                                :error="requireFields['confirmPassword'].error"
                                :placeholder="'Confirm Password'"
                            >
                                <template #label>
                                    <label>Confirm Password</label>
                                </template>
                            </BaseInput>
                        </div>
                    </template>
                </BaseBorderPanel>
                <BaseBorderPanel>
                    <template #title>
                        <h3 class="typo-heading-h3">Permissions for user</h3>
                    </template>
                    <template #label>
                        <p class="typo-heading-small-semi-bold-gray">Select what pages user can use.</p>
                    </template>
                    <template #content>
                        <BasePermissionsList v-model="permissions" :design="'rows'" />
                    </template>
                </BaseBorderPanel>
                <BaseWarningBox :type="'error'" :text="warningMessage" />
            </div>
        </template>
        <template #action-buttons>
            <BaseButton :text="'Cancel'" :design="'outline'" @click="close()" />
            <BaseButton :text="'Create'" :load="requestIsPending" @click="create()" />
        </template>
    </BaseModal>
</template>

<style scoped>
h3 {
    margin-bottom: 10px;
}

.items {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(4, 255px);
    gap: 20px;
}

.switch-content {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.actions {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 50px;
}

.warning-box {
    text-align: center;
    margin-bottom: -30px;
}
</style>
